<section>
    <div class="content">
        <div class="modal-header">
            <div><h4 class="title mb-0"> {{'ADDUSER.SIMPLE' | translate}} </h4></div>
            <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
        </div>

        <div class="modal-body">

            <form [formGroup]="addUserForm" (ngSubmit)="addUser()">
                <div class="row">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="mb-1">
                                    <label class="form-label"> Username <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <input formControlName="username" name="number" id="name" type="text" class="form-control" placeholder="Email adress">
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="mb-1">
                                    <label class="form-label"> {{'ADDUSER.FIRSTNAME' | translate}} <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <input formControlName="firstName"  type="text" class="form-control" placeholder="{{'ADDUSER.FIRSTNAME' | translate}}">
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="mb-1">
                                    <label class="form-label"> {{'ADDUSER.LASTNAME' | translate}} <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <input formControlName="lastName" name="username" id="username" type="text" class="form-control" placeholder="{{'ADDUSER.LASTNAME' | translate}}">
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="mb-1">
                                    <label class="form-label"> {{'ADDUSER.PHONE' | translate}} <span class="text-danger">*</span></label>
                                    <div class="form position-relative">
                                        <input formControlName="phone" name="url" id="model" type="text" class="form-control" placeholder="{{'ADDUSER.PHONE' | translate}}">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="mb-1">
                                    <label class="form-label"> {{'ADDUSER.COUNTIES' | translate}} <span class="text-danger">*</span></label>
                                    <div class="form position-relative">
                                        <input formControlName="counties" name="url" type="text" class="form-control"
                                        placeholder="{{'ADDUSER.COUNTIESPLACE' | translate}}">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="mb-1">
                                    <label class="form-label"> Status <span class="text-danger">*</span></label>
                                    <select  class="form-select form-control" formControlName="status">
                                        <option value=""> -Select- </option>
                                        <option value="true"> Active </option>
                                        <option value="false"> Inactive </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="mb-1">
                                    <label class="form-label"> {{'ADDUSER.ROL' | translate}} <span class="text-danger">*</span></label>
                                    <select  class="form-select form-control" formControlName="role">
                                        <option value=""> -Select- </option>
                                        <option value="USER"> USER </option>
                                        <option *ngIf="isAdmin" value="ADMIN"> ADMIN </option>
                                        <option *ngIf="isAdmin" value="MANAGER"> MANAGER </option>
                                    </select>
                                </div>
                            </div>
                        </div>


                </div>
                <div class="modal-footer">
                    <div class="col-lg-12 ">
                        <button type="submit" style="padding-right: 10px" class="btn btn-primary" [disabled]="addUserForm.invalid">Adauga</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="alert alert-danger" *ngIf="errorMessage">
        {{'ADDUSER.ERRORMESSAGE' | translate}}  <br/> {{ errorMessage }}
    </div>
    <div class="alert alert-success justify-content-center" *ngIf="!errorMessage && isAdd">
        {{'ADDUSER.SUCCESSMESSAGE' | translate}}
    </div>
</section>
