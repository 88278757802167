import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home-comp/home.component';
import { MasterPageComponent } from './shared/master-page/master-page.component';
import { PageTermsComponent } from './shared/page-terms/page-terms.component';
import { PagePrivacyComponent } from './shared/page-privacy/page-privacy.component';
import { StatusPageComponent } from './home/status-page/status-page.component';
import { OrderFormularComponent } from './home/order-formular/order-formular.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { GeneralComponent } from './shared/general/general.component';
import { UsersComponent } from './admin/users/users.component';
import { OrdersComponent } from './admin/orders/orders.component';
import { LoginComponent } from './auth/login/login.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { SignupComponent } from './auth/signup/signup.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { SetNewPasswordComponent } from './auth/set-new-password/set-new-password.component';
import { SettingsComponent } from './admin/settings/settings.component';
import {TemplateNewsletterComponent} from './templates/template-newsletter/template-newsletter.component';
import {OrderFormularInternComponent} from "./home/order-formular-intern/order-formular-intern.component";

const routes: Routes = [
  {
    path: '', component: MasterPageComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'page-terms', component: PageTermsComponent},
      { path: 'page-privacy', component: PagePrivacyComponent},
      { path: 'app-status-page', component: StatusPageComponent},
      { path: 'formular', component: OrderFormularComponent},
      { path: 'formularIntern', component: OrderFormularInternComponent},
      { path: 'login', component: LoginComponent},
      { path: 'signup', component: SignupComponent},
      { path: 'forgotPassword', component: ForgotPasswordComponent},
      { path: 'resetPassword', component: SetNewPasswordComponent},
      { path: 'newsletter', component: TemplateNewsletterComponent}
    ],
  },
  {
    path: 'profile', component: GeneralComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'users', component: UsersComponent },
      { path: 'orders', component: OrdersComponent},
      { path: 'settings', component: SettingsComponent},
    ]
  },
  { path: '**', pathMatch: 'full', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled',
  scrollOffset: [0, 0],
  // Enable scrolling to anchors
  anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
