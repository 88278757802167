<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level">
                    <div class="tab-content rounded shadow bg-white py-4 px-5">
                        <form id="form_awb" method="post" enctype="multipart/form-data" class="nobottommargin">
                            <div class="d-flex justify-content-center">
                                <div class="col-md-3">
                                    <div class="form">
                                        <div class="controls">
                                            <input placeholder="{{ 'SECTION1.PLACEHOLDER' | translate }}" [formControl]="awb"  id="awb" name="AWB">
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button type="submit" (click)="getDateForStatus(); getLastStatus()"  class="btn btn-primary align-content-center" style="margin-top: -7px;">
                                        <span>{{'SECTION1.BUTTON' | translate}}</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<section  style="padding-top: 100px; padding-bottom: 100px">
    <div class="container">
        <ul class="progressbar" >
            <li [ngClass]="{'active': progressPercent > 0}" style="z-index: 9"  [ngStyle]="{'width' : width + '%' }">Comanda confirmata
                <div> <span [innerHTML]="dateId1"> </span> </div>
                <div class="position-sticky">
                    <div style="margin-top: -152px">
                        <i class="mdi mdi-clipboard" style="font-size:2rem;"></i>
                    </div>
                </div>
            </li>
            <li [ngClass]="{'active': progressPercent > 1}" style="z-index: 8"  [ngStyle]="{'width' : width + '%' }">Colet preluat <br>
                <div> <span [innerHTML]="dateId2"> </span> </div>
                <div class="position-sticky">
                    <div style="margin-top: -152px">
                            <i class="mdi mdi-cube" style="font-size:2rem;"></i>
                    </div>
                </div>
            </li>
            <li [ngClass]="{'active': progressPercent > 2}" style="z-index: 7"  [ngStyle]="{'width' : width + '%' }">Colet in depozit
                <div> <span [innerHTML]="dateId3">  </span> </div>
                <div class="position-sticky">
                    <div style="margin-top: -152px">
                            <i class="mdi mdi-cube-unfolded" style="font-size:2rem;"  [ngStyle]="{'width' : width + '%' }"></i>
                    </div>
                </div>
            </li>
            <li [ngClass]="{'active': progressPercent > 3}" style="z-index: 6"  [ngStyle]="{'width' : width + '%' }">Colet in curs de livrare
                <div> <span [innerHTML]="dateId4">  </span></div>
                <div class="position-sticky" >
                    <div style="margin-top: -152px">
                            <i class="mdi mdi-truck" style="font-size:2rem;"></i>
                    </div>
                </div>
            </li>
            <li *ngIf="progressPercent === 5" [ngClass]="{'active': progressPercent > 4}" style="z-index: 4"  [ngStyle]="{'width' : width + '%' }">Livrare amanata
                <div> <span [innerHTML]="dateId5">  </span> </div>
                <div class="position-sticky">
                    <div style="margin-top: -152px">
                            <i class="mdi mdi-clock" style="font-size:2rem;"></i>
                    </div>
                </div>
            </li>
            <li [ngClass]="{'active': progressPercent > 5}" style="z-index: 3"  [ngStyle]="{'width' : width + '%' }">Comanda livrata
                <div> <span [innerHTML]="dateId6"> </span> </div>
                <div class="position-sticky">
                    <div style="margin-top: -152px;">
                            <i class="mdi mdi-home" style="font-size:2rem;"></i>
                    </div>
                </div>
            </li>
            <div style="margin-top: 150px">
            <li *ngIf="progressPercent === 7 || progressPercent === 8" [ngClass]="{'active': progressPercent > 6}" style="z-index: 2"  [ngStyle]="{'width' : 50 + '%' }">Retur <br>
                <div> <span [innerHTML]="dateR"> </span> </div>
                <div class="position-sticky">
                    <div style="margin-top: -152px">
                        <i class="mdi mdi-keyboard-return" style="font-size:2rem;"></i>
                    </div>
                </div>
            </li>
            <li *ngIf="progressPercent === 7 || progressPercent === 8" [ngClass]="{'active': progressPercent > 7}" style="z-index: 1"  [ngStyle]="{'width' : 50 + '%' }">Refund realizat
                <div> <span [innerHTML]="dateRF"> </span> </div>
                <div class="position-sticky">
                    <div style="margin-top: -152px">
                        <i class="mdi mdi-cash-refund" style="font-size:2rem;"></i>
                    </div>
                </div>
            </li>
            </div>
        </ul>
    </div>
</section>


