<section class="bg-half-125 pb-0 bg-primary d-table w-100" id="home">

    <div class="container">
        <div class="text-center">
            <h1 class="text-white-50">{{ 'SECTION1.TITLE1' | translate }}</h1>
            <h3 class="heading text-white mb-3 title-dark"> {{ 'SECTION1.TITLE2' | translate }}</h3>
            <h3 class="text-white h2 t400 mb-5"><a href="tel:{{telefon}}" class="text-white h2 t400 mb-2">{{telefon}}</a></h3>
        </div>
        <div class="tab-content rounded shadow bg-white">
            <form id="form_awb" method="post" enctype="multipart/form-data" class="nobottommargin">
                <div class="d-flex justify-content-center" style="padding: 10px;">
                    <a style="font-size: 35px" class="text-primary"><i style="font-size: 40px" class="mdi mdi-cursor-default-click"></i>{{ 'SECTION1.CHECKAWB' | translate }}
                        &nbsp;&nbsp;&nbsp;&nbsp;</a>
                    <button type="submit" class="btn btn-primary align-content-center" (click)="goToStatus('/app-status-page')">
                        <i style="font-size: 20px" class="mdi mdi-cursor-default-click"></i>
                        <span>{{'SECTION1.BUTTON' | translate}}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>


    <div class="container py-4 px-0" style="padding-bottom: 50px;">
        <div class="p-4">
            <div class="row">
                <div class="col-lg-12 rounded" style="background-color: white;">
                    <ul ngbNav #nav="ngbNav" class="nav-pills nav-justified rounded" style="margin-top: 15px">
                        <li ngbNavItem>
                            <a ngbNavLink class="nav-item" style="padding: 17px;">
                                <i class="mdi mdi-map-marker"></i>ROMANIA - ROMANIA
                                <span class="badge rounded-pill bg-danger">{{'SECTION1.NEW' | translate}}</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="tab-content rounded-bottom shadow bg-white py-4 px-5">

                                    <form [formGroup]="destination" (ngSubmit)="onSubmit()">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xlg-3 col-12">
                                                <label> {{'SECTION1.COLLECTION' | translate}} </label>
                                                <select class="form-select form-control" formControlName="collectingCounty"
                                                    (change)="getCollectingLocality(); onCompleteCountyLocality(); onSubmit();">
                                                    <option value="">{{'SECTION1.SELECT' | translate}}</option>
                                                    <option *ngFor="let county of countiesCollecting"> {{county}}</option>
                                                </select>
                                            </div>

                                            <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xlg-3 col-12">
                                                <label> {{'SECTION1.COLLECTIONLOCALITY' | translate}} </label>
                                                <select class="form-select form-control" formControlName="collectingLocality" (change)="onCompleteCountyLocality(); onSubmit();">
                                                    <option value="">{{'SECTION1.SELECT' | translate}}</option>
                                                    <option value="{{locality.locality}}" *ngFor="let locality of localitiesCollecting"> {{locality.locality}} ({{locality.km}} km)
                                                    </option>
                                                </select>
                                            </div>

                                            <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xlg-3 col-12">
                                                <label> {{'SECTION1.DESTINATION' | translate}} </label>
                                                <select class="form-select form-control" formControlName="deliveryCounty"
                                                    (change)="getDestinationLocality(); onCompleteCountyLocality(); onSubmit();">
                                                    <option value="">{{'SECTION1.SELECT' | translate}}</option>
                                                    <option *ngFor="let county of countiesDestination "> {{county}} </option>
                                                </select>
                                            </div>

                                            <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xlg-3 col-12">
                                                <label> {{'SECTION1.DESTINATIONLOCALITY' | translate}} </label>
                                                <select class="form-select form-control" formControlName="deliveryLocality" (click)="onCompleteCountyLocality(); onSubmit()">
                                                    <option value="">{{'SECTION1.SELECT' | translate}}</option>
                                                    <option value="{{locality.locality}}" *ngFor="let locality of localitiesDestination"> {{locality.locality}} ({{locality.km}}
                                                        km)
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </form>
                                    <br>
                                    <form [formGroup]="packages">
                                        <div *ngIf="packages.controls.itemRows != null">
                                            <div *ngFor="let itemrow of packages.controls.itemRows.controls; let i=index" [formGroup]="itemrow" class="row">
                                                <div class="col-sm-12 col-md-1 col-lg-1 col-xl-1 col-xlg-1 col-12 form-group">
                                                    <label><i class="mdi mdi-sort-numeric-descending"></i> Nr. </label>
                                                    <input disabled class="form-control" formControlName="Nr">
                                                </div>

                                                <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xlg-2 col-12 form-group">
                                                    <label><i class=""></i> {{'SECTION1.PACHET' | translate}} </label>
                                                    <select class="sm-form-control valid form-select form-control" required="" name="selected_service"
                                                        (change)="onSubmit(); getItemField(itemrow)" formControlName="Pachet">
                                                        <option value="">{{'SECTION1.SELECT' | translate}}</option>
                                                        <option value="PACHET">{{'SECTION1.PACHET' | translate}}</option>
                                                        <option value="PLIC"> {{'SECTION1.PLIC' | translate}} </option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xlg-2 col-12 form-group">
                                                    <label> <i class="mdi mdi-weight-kilogram"></i> {{'SECTION1.GREUTATE' | translate}} (Kg) </label>
                                                    <div class="input-group">
                                                        <span class="input-group-addon bootstrap-touchspin-prefix input-group-prepend">
                                                            <span class="input-group-text"><i class="mdi mdi-weight-kilogram"></i></span>
                                                        </span>
                                                        <input type="number" class="form-control weight" placeholder="ex. 0.5, 1" required="" min="0.5" max="1000000" step="0.5"
                                                            formControlName="Greutate" (keyup)="onSubmit();" (change)="onSubmit()">
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xlg-2 col-12 form-group"
                                                    [ngClass]="{'invisible': itemrow.value.Pachet === 'PLIC'}" id="itemcoll_12">
                                                    <label><i class="mdi mdi-ruler-square"></i> {{'SECTION1.LUNGIME' | translate}} (cm) </label>
                                                    <div class="input-group">
                                                        <span class="input-group-addon bootstrap-touchspin-prefix input-group-prepend">
                                                            <span class="input-group-text"><i class="mdi mdi-ruler-square"></i></span>
                                                        </span>
                                                        <input type="number" class="form-control length" placeholder="ex. 1" required="" min="0.5" (keyup)="onSubmit()"
                                                            (change)="onSubmit()" max="1000000" step="0.5" formControlName="Lungime">
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xlg-2 col-12 form-group"
                                                    [ngClass]="{'invisible': itemrow.value.Pachet === 'PLIC'}" id="itemcolw_12">
                                                    <label><i class="mdi mdi-ruler"></i> {{'SECTION1.LATIME' | translate}} (cm) </label>
                                                    <div class="input-group">
                                                        <span class="input-group-addon bootstrap-touchspin-prefix input-group-prepend">
                                                            <span class="input-group-text"><i class="mdi mdi-ruler"></i></span>
                                                        </span>
                                                        <input type="number" class="form-control" placeholder="ex. 1" required="" min="0.5" max="1000000" (keyup)="onSubmit()"
                                                            (change)="onSubmit()" step="0.5" formControlName="Latime">
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xlg-2 col-12 form-group"
                                                    [ngClass]="{'invisible': itemrow.value.Pachet === 'PLIC'}">
                                                    <label><i class="mdi mdi-table-row-height"></i> {{'SECTION1.INALTIME' | translate}} (cm) </label>
                                                    <div class="input-group">
                                                        <span class="input-group-addon bootstrap-touchspin-prefix input-group-prepend">
                                                            <span class="input-group-text"><i class="mdi mdi-table-row-height"></i></span>
                                                        </span>
                                                        <input type="number" class="form-control height" placeholder="ex. 1" required="" min="0.5" max="1000000" step="0.5"
                                                            (keyup)="onSubmit()" (change)="onSubmit()" formControlName="Inaltime">
                                                    </div>
                                                </div>

                                                <div style="height: 50px; width: 74px; padding-top: 22px;">
                                                    <button class="btn btn-icon btn-pills btn-outline-primary" (click)="deleteRow(i, packages); onSubmit()">x</button>
                                                </div>
                                                <p></p>
                                            </div>
                                        </div>
                                    </form>
                                    <div *ngIf="adauga">
                                        <button class="btn btn-outline-primary" style="margin-left: 20px" (click)="addPackage(packages); onSubmit()"><i
                                                class="mdi mdi-shape-circle-plus"></i>{{'SECTION1.ADAUGAPLIC' | translate}}</button>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="margoitem_1">
                                            <span style="margin-left: 20px; font-size: x-large" class="text-primary fw-bold" [innerHTML]="cost"></span> &nbsp;&nbsp;
                                            <span type="button" (click)="goToPage('/formular')" [innerHTML]="continua"></span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>

                        </li>

                        <li ngbNavItem>
                            <a ngbNavLink class="nav-item" style="padding: 17px;">
                                <i class="mdi mdi-earth"></i> {{'SECTION1.INTERNATIONAL' | translate}}
                            </a>
                            <ng-template ngbNavContent>
                                <div class="tab-content rounded-bottom shadow bg-white py-4 px-5">

                                    <form [formGroup]="international" (ngSubmit)="onSubmitInternational()">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xlg-6 col-12">
                                                <label>{{'SECTION1.INTERNATIONALCOLECT' | translate}}</label>
                                                <select class="form-select form-control" formControlName="countryCollecting"
                                                    (change)="getCountryes(); onCompleteCountyLocalityInternation(); onSubmitInternational();getDestinationCountRies()">
                                                    <option value="">{{'SECTION1.SELECT' | translate}}</option>
                                                    <option value="Romania" [ngValue]="'Romania'">Romania</option>
                                                    <option value="{{country}}" [ngValue]=country *ngFor="let country of countriesDestinationStabil"> {{country}} </option>
                                                </select>
                                            </div>

                                            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xlg-6 col-12">
                                                <label> {{'SECTION1.INTERNATIONALDEST' | translate}}</label>
                                                <select class="form-select form-control" formControlName="countryDestination"
                                                    (change)=" onCompleteCountyLocalityInternation(); onSubmitInternational()">
                                                    <option value="">{{'SECTION1.SELECT' | translate}}</option>
                                                    <option value="{{country}}" *ngFor="let country of countriesDestination">{{country}}</option>
                                                </select>
                                            </div>

                                        </div>
                                    </form>

                                    <br>

                                    <form [formGroup]="packageInternational">
                                        <div *ngIf="packageInternational.controls.itemRows != null">
                                            <div *ngFor="let itemrow of packageInternational.controls.itemRows.controls; let i=index" [formGroup]="itemrow" class="row">
                                                <div class="col-sm-12 col-md-1 col-lg-1 col-xl-1 col-xlg-1 col-12 form-group">
                                                    <label><i class="mdi mdi-sort-numeric-descending"></i> Nr. </label>
                                                    <input disabled class="form-control" formControlName="Nr">
                                                </div>

                                                <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xlg-2 col-12 form-group">
                                                    <label><i class=""></i> {{'SECTION1.PACHET' | translate}} </label>
                                                    <select class="sm-form-control valid form-select form-control" required="" name="selected_service" formControlName="Pachet"
                                                        (change)="onSubmitInternational(); getItemField(itemrow)">
                                                        <option value="">{{'SECTION1.SELECT' | translate}}</option>
                                                        <option value="PACHET" selected="">{{'SECTION1.PACHET' | translate}}</option>
                                                        <option value="PLIC"> {{'SECTION1.PLIC' | translate}} </option>
                                                    </select>
                                                </div>

                                                <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xlg-2 col-12 form-group">
                                                    <label> <i class="mdi mdi-weight-kilogram"></i> {{'SECTION1.GREUTATE' | translate}} (Kg) </label>
                                                    <div class="input-group">
                                                        <span class="input-group-addon bootstrap-touchspin-prefix input-group-prepend">
                                                            <span class="input-group-text"><i class="mdi mdi-weight-kilogram"></i></span>
                                                        </span>
                                                        <input type="number" class="form-control weight" placeholder="ex. 0.5, 1" required="" min="0.5" max="1000000" step="0.5"
                                                            formControlName="Greutate" (keyup)="onSubmitInternational()" (change)="onSubmitInternational()">
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xlg-2 col-12 form-group"
                                                    [ngClass]="{'invisible': itemrow.value.Pachet === 'PLIC'}">
                                                    <label><i class="mdi mdi-ruler-square"></i> {{'SECTION1.LUNGIME' | translate}}(cm) </label>
                                                    <div class="input-group">
                                                        <span class="input-group-addon bootstrap-touchspin-prefix input-group-prepend">
                                                            <span class="input-group-text"><i class="mdi mdi-ruler-square"></i></span>
                                                        </span>
                                                        <input type="number" class="form-control length" placeholder="ex. 1" required="" min="0.5" max="1000000" step="0.5"
                                                            (keyup)="onSubmitInternational()" (change)="onSubmitInternational()" formControlName="Lungime">
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xlg-2 col-12 form-group"
                                                    [ngClass]="{'invisible': itemrow.value.Pachet === 'PLIC'}">
                                                    <label><i class="mdi mdi-ruler"></i> {{'SECTION1.LATIME' | translate}} (cm) </label>
                                                    <div class="input-group">
                                                        <span class="input-group-addon bootstrap-touchspin-prefix input-group-prepend">
                                                            <span class="input-group-text"><i class="mdi mdi-ruler"></i></span>
                                                        </span>
                                                        <input type="number" class="form-control" value="" placeholder="ex. 1" required="" min="0.5" max="1000000" step="0.5"
                                                            (keyup)="onSubmitInternational()" (change)="onSubmitInternational()" formControlName="Latime">
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xlg-2 col-12 form-group"
                                                    [ngClass]="{'invisible': itemrow.value.Pachet === 'PLIC'}">
                                                    <label><i class="mdi mdi-table-row-height"></i> {{'SECTION1.INALTIME' | translate}} (cm) </label>
                                                    <div class="input-group">
                                                        <span class="input-group-addon bootstrap-touchspin-prefix input-group-prepend">
                                                            <span class="input-group-text"><i class="mdi mdi-table-row-height"></i></span>
                                                        </span>
                                                        <input type="number" class="form-control height" placeholder="ex. 1" required="" min="0.5" max="1000000" step="0.5"
                                                            (keyup)="onSubmitInternational()" (change)="onSubmitInternational()" formControlName="Inaltime">
                                                    </div>
                                                </div>

                                                <div style="height: 50px; width: 74px; padding-top: 22px;">
                                                    <button class="btn btn-icon btn-pills btn-outline-primary"
                                                        (click)="deleteRow(i,packageInternational); onSubmitInternational()">x
                                                    </button>
                                                </div>
                                                <p></p>
                                            </div>
                                        </div>
                                    </form>
                                    <div *ngIf="adaugaInternational">
                                        <button class="btn btn-outline-primary" style="margin-left: 20px" (click)="addPackage(packageInternational); onSubmitInternational()"><i
                                                class="mdi mdi-shape-circle-plus"></i>{{'SECTION1.ADAUGAPLIC' | translate}}</button>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="margoitem_1 d-flex gap-3">
                                            <button type="submit" class="btn btn-primary rounded-lg p-2" *ngFor="let item of obiectDeMapat | keyvalue"
                                                [disabled]="item.value === 'Indisponibil'" (click)="goToPageIntern('/formularIntern', item.value, item.key)">
                                                {{ item.key }}: &nbsp;{{ item.value }}
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </ng-template>
                        </li>

                    </ul>
                    <div [ngbNavOutlet]="nav" class="pt-2"></div>
                </div>
            </div>
        </div>

    </div>
</section>

<section class="section" id="whatwedo">
    <div class="container">
        <div class="col-lg-12 text-center">
            <div class="heading-block">
                <h3 class="nott mb-3 t600 ls0">{{'SECTION2.TITLE' | translate}}</h3>
                <p class="mb-2" [innerHTML]="'SECTION2.SUBTITLE' | translate "></p>
            </div>
        </div>

        <div class="col lg-8">
            <div class="card blog rounded bg-light overflow-hidden border-0 m-2" style="margin-top: 3rem !important;">
                <div class="row align-items-center">
                    <div class="col-md-5">
                        <img src="../../../assets/images/kurier/development.svg" class="img-fluid" alt="">
                    </div>
                    <div class="col-md-7">
                        <div class="card-body content">
                            <h4>{{'SECTION2.SOLUTIONS.TITLE' | translate}}</h4>
                            <p class="text-muted h6 mb-0 ">{{'SECTION2.SOLUTIONS.DETAILS' | translate}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card blog rounded border-0 m-2 overflow-hidden" style="margin-top: 3rem !important;">
                <div class="row align-items-center g-0">
                    <div class="col-md-6 order-2 order-md-1">
                        <div class="card-body content">
                            <h4>{{'SECTION2.DELIVERY.TITLE' | translate}}</h4>
                            <p class="text-muted h6 mb-0">{{'SECTION2.DELIVERY.DETAILS' | translate}}</p>
                        </div>
                    </div>
                    <div class="col-md-6 order-1 order-md-2  d-flex justify-content-center">
                        <img src="../../../assets/images/kurier/envelope.svg" style="width: 444px; height: 362px;" class="img-fluid" alt="">
                    </div>
                </div>
            </div>

            <div class="card blog rounded bg-light overflow-hidden border-0 m-2" style="margin-top: 3rem !important;">
                <div class="row align-items-center">
                    <div class="col-md-5">
                        <img src="../../../assets/images/kurier/02.png" style="height: 362px;" class="img-fluid" alt="">
                    </div>
                    <div class="col-md-7">
                        <div class="card-body content">
                            <h4>{{'SECTION2.WIDERANGE.TITLE' | translate}}</h4>
                            <p class="text-muted h6 mb-0 ">{{'SECTION2.WIDERANGE.DETAILS' | translate}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="position-relative">
        <div class="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>

</section>

<section class="section" style="padding-top: 5px;">
    <div class="section p-0 dark mb-0" style="background: linear-gradient(to right, rgba(25,102,221,0.2), rgba(25,102,221,0.5)),
       url('../../../assets/images/kurier/r1.jpg') no-repeat center center / cover; ">
        <div class="container">
            <div class="row justify-content-between mb-4">
                <div class="col-lg-5 col-md-6 offset-lg-1 pt-3">
                    <h3 class="display-5 t700 text-white topmargin-lg" style="padding-top: 135px">{{'SECTION3.TITLE' | translate}}</h3>
                </div>
                <div class="col-lg-5 col-md-6 mb-0 mb-md-5" style="padding-top: 88px">
                    <p class=" text-white mb-5" [innerHTML]="'SECTION3.DESCRIPTION' | translate "></p>
                    <h3 class="mb-2 t700 text-white topmargin-lg">{{'SECTION3.REASONS.TITLE' | translate}}</h3>
                    <div class="d-flex">
                        <ul class="text-white col-6">
                            <!--            <li class="my-2"><i class="mdi mdi-check-circle-outline"></i> <span class="pl-2">100% De Incredere</span></li>-->
                            <li class="my-2"><i class=""></i> <span class="pl-2">{{'SECTION3.REASONS.TRUSTED' | translate}}</span></li>
                            <li class="my-2"><i class=""></i> <span class="pl-2">{{'SECTION3.REASONS.SAFE' | translate}}</span></li>
                            <li class="my-2"><i class=""></i> <span class="pl-2">{{'SECTION3.REASONS.TIME' | translate}}</span></li>
                            <li class="my-2"><i class=""></i> <span class="pl-2">{{'SECTION3.REASONS.VERIFY' | translate}}</span></li>
                            <li class="my-2"><i class=""></i> <span class="pl-2">{{'SECTION3.REASONS.AUTHORIZED' | translate}}</span></li>
                            <li class="my-2"><i class=""></i> <span class="pl-2">{{'SECTION3.REASONS.TAXES' | translate}}</span></li>
                        </ul>
                        <ul class="text-white col-6 iconlist">
                            <li class="my-2"><i class="icon-line-circle-check t300"></i> <span class="pl-2">{{'SECTION3.REASONS.SUPPORT' | translate}}</span></li>
                            <li class="my-2"><i class="icon-line-circle-check t300"></i> <span class="pl-2">{{'SECTION3.REASONS.NOEXTRA' | translate}}</span></li>
                            <li class="my-2"><i class="icon-line-circle-check t300"></i> <span class="pl-2">{{'SECTION3.REASONS.SUNDAY' | translate}}</span></li>
                            <li class="my-2"><i class="icon-line-circle-check t300"></i> <span class="pl-2">{{'SECTION3.REASONS.FOLLOW' | translate}}</span></li>
                            <li class="my-2"><i class="icon-line-circle-check t300"></i> <span class="pl-2">{{'SECTION3.REASONS.INTERNATIONAL' | translate}}</span></li>
                            <li class="my-2"><i class="icon-line-circle-check t300"></i> <span class="pl-2">{{'SECTION3.REASONS.DOORTODOOR' | translate}}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!--  <div class="clear"></div>-->
        <svg class="svg-curve" xmlns="" viewBox="0 0 1463 188.03">
            <path style="fill:#FFF;" d="M-.5,288.5s297-175,792-97,599,52,671,25v143H-.5Z" transform="translate(0 -171.47)" />
        </svg>
    </div>
</section>

<section>
    <div class="section-map clearfix bottommargin-lg topmargin-lg">
        <div class="container">
            <div class="section p-0 dark mb-0 ">
                <h2 class="text-center"><span class="text-primary fw-bold">
                        {{'SECTION4.TITLE' | translate}}</span></h2>
                <div class="d-flex justify-content-center">
                    <div id="hotspot-img" class="hotspot-img mt-2 responsive-hotspot-wrap">
                        <img src="../../../assets/images/kurier/map.png" class="img-fluid" alt="">
                        <div class="hot-spot" style="top: 287px; left: 140px;">
                            <div class="circle"></div>
                            <div class="tooltip">
                                <p>San Francisco</p>
                            </div>
                        </div>
                        <div class="hot-spot" style="top: 237px; left: 311px">
                            <div class="circle"></div>
                            <div class="tooltip">
                                <p>Toronto</p>
                            </div>
                        </div>
                        <div class="hot-spot" style="top: 500px; left: 382px;">
                            <div class="circle"></div>
                            <div class="tooltip">
                                <p>Brasilla</p>
                            </div>
                        </div>
                        <div class="hot-spot" style="top: 538px; left: 581px;">
                            <div class="circle"></div>
                            <div class="tooltip">
                                <p>Cape Town</p>
                            </div>
                        </div>
                        <div class="hot-spot" style="top: 371px; left: 761px;">
                            <div class="circle"></div>
                            <div class="tooltip">
                                <p>Bangalore</p>
                            </div>
                        </div>
                        <div class="hot-spot" style="top: 248px; left: 562px;">
                            <div class="circle"></div>
                            <div class="tooltip">
                                <p>Europe</p>
                            </div>
                        </div>
                        <div class="hot-spot" style="top: 526px; left: 984px;">
                            <div class="circle"></div>
                            <div class="tooltip">
                                <p>Sydney</p>
                            </div>
                        </div>
                        <div class="hot-spot" style="top: 416px; left: 838px;">
                            <div class="circle"></div>
                            <div class="tooltip">
                                <p>Singapore</p>
                            </div>
                        </div>
                        <div class="hot-spot" style="top: 307px; left: 943px;">
                            <div class="circle"></div>
                            <div class="tooltip">
                                <p>Tokyo</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-4 pt-2 justify-content-center" style="z-index: 1; padding-bottom: 30px">
        <div class="col-md-3 col-6 mt-4 pt-2">
            <div class="counter-box text-center">
                <div class="counter-dots"></div>
                <h2 class="mb-0 mt-4"><span class="counter-value" [CountTo]="11000" [from]="0" [duration]="4"></span>+</h2>
                <h5 class="counter-head text-muted"><span class="text-primary fw-bold">{{'SECTION4.PACKAGES' | translate}}</span></h5>
            </div>
        </div>

        <div class="col-md-3 col-6 mt-4 pt-2">
            <div class="counter-box text-center">
                <div class="counter-dots"></div>
                <h2 class="mb-0 mt-4"><span class="counter-value" [CountTo]="10" [from]="0" [duration]="4"></span>+</h2>
                <h5 class="counter-head text-muted"><span class="text-primary fw-bold">{{'SECTION4.YEARS' | translate}}</span></h5>
            </div>
        </div>

        <div class="col-md-3 col-6 mt-4 pt-2">
            <div class="counter-box text-center">
                <div class="counter-dots"></div>
                <h2 class="mb-0 mt-4"><span class="counter-value" [CountTo]="258" [from]="0" [duration]="4"></span>+</h2>
                <h5 class="counter-head text-muted"><span class="text-primary fw-bold">{{'SECTION4.COUNTRIES' | translate}}</span></h5>
            </div>
        </div>
    </div>
</section>


<section style="background-color: aliceblue">
    <div class="row mt-4 pt-2 justify-content-center" id="counter" style="padding-bottom: 40px;">
        <div class="col-md-3 col-6 mt-4 pt-2">
            <div class="counter-box text-center">
                <img src="../../../assets/images/kurier/chat.svg" class="avatar avatar-medium" alt="">
                <h5 class="mb-0 mt-4"><span class="text-primary fw-bold">{{'SECTION5.PLANNING.TITLE' | translate}}</span></h5>
                <h6 class="counter-head text-muted">{{'SECTION5.PLANNING.DESCRIPTION' | translate}}</h6>

            </div>
        </div>

        <div class="col-md-3 col-6 mt-4 pt-2">
            <div class="counter-box text-center">
                <img src="../../../assets/images/kurier/Asset186.svg" class="avatar avatar-medium" alt="">
                <h5 class="mb-0 mt-4"><span class="text-primary fw-bold">{{'SECTION5.OPTIMIZATION.TITLE' | translate}}</span></h5>
                <h6 class="counter-head text-muted">{{'SECTION5.OPTIMIZATION.DESCRIPTION' | translate}}</h6>
            </div>
        </div>

        <div class="col-md-3 col-6 mt-4 pt-2">
            <div class="counter-box text-center">
                <img src="../../../assets/images/kurier/fast.svg" class="avatar avatar-medium" alt="">
                <h5 class="mb-0 mt-4"><span class="text-primary fw-bold">{{'SECTION5.RESULT.TITLE' | translate}}</span></h5>
                <h6 class="counter-head text-muted">{{'SECTION5.RESULT.DESCRIPTION' | translate}}</h6>
            </div>
        </div>
    </div>
</section>

<section style="background: url('assets/images/kurier/build.png') bottom no-repeat;">
    <h2 class="col-lg-12 text-center col-md-4  " style="padding: 40px">
    </h2>
</section>