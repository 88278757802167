import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

const ROOT_URL = environment.baseUrl + '/api/admin/user/';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true
};

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(private http: HttpClient) {}

    getUsers(): Observable<any>{
        return this.http.get( ROOT_URL, httpOptions );
    }

    addUser(username: string, firstName: string, lastName: string, phone: string, counties: string,
            status: boolean, role: string): Observable<any> {
        return this.http.post(ROOT_URL, {
                username,
                firstName,
                lastName,
                phone,
                counties,
                status,
                role
            },
            httpOptions
        );
    }

    updateUser(userId: number, username: string, firstName: string, lastName: string, phone: string, counties: string, status: boolean,
               token: string, role: string): Observable<any>{
        return this.http.put(
            ROOT_URL + userId,
            {
                username, firstName, lastName, phone,
                counties, status, token, role
            },
            httpOptions,
        );
    }

    deleteUser(carId: number): Observable<any>{
        return this.http.delete(
            ROOT_URL + carId,
            httpOptions,
        );
    }

    userDetails(userId: number): Observable<any>{
        return this.http.get(
            ROOT_URL + userId, httpOptions);
    }

}
