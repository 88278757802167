<div class="">
    <div class="table-responsive bg-white shadow rounded" >
        <form>
            <br>
         <button (click)="addTable()" class="btn-outline-dark"> {{'PACKAGESMODEL.ADAUGA' | translate}} </button> <br> <br>
        <table class="table table-bordered table-responsive-md table-striped text-center">
            <thead>
            <tr>
                <th scope="col" class="border-bottom">Nr.</th>
                <th scope="col" class="border-bottom" style="width: 111px">{{'PACKAGESMODEL.TYPE' | translate}}</th>
                <th scope="col" class="border-bottom">{{'PACKAGESMODEL.AMOUNT' | translate}}</th>
                <th scope="col" class="border-bottom">{{'PACKAGESMODEL.LENGTH' | translate}}</th>
                <th scope="col" class="border-bottom">{{'PACKAGESMODEL.WIDTH' | translate}}</th>
                <th scope="col" class="border-bottom">{{'PACKAGESMODEL.HEIGHT' | translate}}</th>
                <th scope="col" class="border-bottom">{{'PACKAGESMODEL.WEIGHT' | translate}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of packageData; let i = index;">
                <td  contenteditable="true">{{i+1}}</td>
                <td  contenteditable="true">
                    <select [(ngModel)]="data.type" value="{{data.type}}" [ngModelOptions]="{standalone: true}" class="form-select form-control" >
                        <option value=""> {{'PACKAGESMODEL.SELECT' | translate}} </option>
                        <option value="PACHET"> {{'PACKAGESMODEL.PACKAGE' | translate}} </option>
                        <option value="PLIC"> {{'PACKAGESMODEL.PLIC' | translate}} </option>
                    </select>
                </td>
                <td  contenteditable="true">
                    <input  [(ngModel)]="data.amount" [ngModelOptions]="{standalone: true}"
                           value="{{data.amount}}" type="number" class="form-control">
                </td>
                <td  contenteditable="true">
                    <input  [(ngModel)]="data.length" [ngModelOptions]="{standalone: true}"
                           value="{{data.length}}" type="number" class="form-control">
                </td>
                <td  contenteditable="true">
                    <input [(ngModel)]="data.width" [ngModelOptions]="{standalone: true}"
                           value="{{data.width}}" type="number" class="form-control">
                </td>
                <td  contenteditable="true">
                    <input  [(ngModel)]="data.height" [ngModelOptions]="{standalone: true}"
                           value="{{data.height}}" type="number" class="form-control">
                </td>
                <td  contenteditable="true">
                    <input  [(ngModel)]="data.weight" [ngModelOptions]="{standalone: true}"
                           value="{{data.weight}}" type="number" class="form-control">
                </td>
                <td>
                    <span class="mb-3 mr-2">
                        <button (click)="deleteRow(i)" class="text-danger">
                            <i class="mdi mdi-delete" aria-hidden="true"></i>
                        </button>
                    </span>
                </td>
            </tbody>
        </table>
        </form>
    </div>
</div>
