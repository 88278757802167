import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-packages-model',
  templateUrl: './packages-model.component.html',
  styleUrls: ['./packages-model.component.css']
})
export class PackagesModelComponent implements OnInit {

  constructor() { }
  @Input() packageData: Array<{
    id: number,
    orderId: number,
    type: string,
    amount: number,
    length: number,
    width: number,
    height: number,
    weight: number
  }>;

  id: number;
  ngOnInit(): void {
  }

  addTable(): void {
    if (this.packageData[0].id === undefined){
      this.id = Math.random();
    } else {
      this.id = this.packageData[0].id;
    }
    const obj = {
      id: this.id,
      orderId: this.packageData[0].orderId,
      type: null,
      amount: null,
      length: null,
      width: null,
      height: null,
      weight: null
    };
    this.packageData.push(obj);
  }

  deleteRow(x): void{
    this.packageData.splice(x, 1 );
  }

}
