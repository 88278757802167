<section class="bg-auth-home bg-circle-gradiant d-table w-100">
    <div class="bg-overlay bg-overlay-white"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-5 col-md-8">
                    <div class="card-body">
                        <h4 class="card-title text-center"> {{'SIGNUP.SIMPLE' | translate}} </h4>
                        <form class="login-form mt-4" class="login-form mt-4"
                              name="form"
                              [formGroup]="form"
                              (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label"> {{'SIGNUP.FIRSTNAME' | translate}} <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                            <input type="text" class="form-control ps-5" placeholder="{{'SIGNUP.FIRSTNAME' | translate}}" name="s"
                                                   formControlName="firstName">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label"> {{'SIGNUP.LASTNAME' | translate}} <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="user-check" class="fea icon-sm icons"></i-feather>
                                            <input type="text" class="form-control ps-5" placeholder="{{'SIGNUP.LASTNAME' | translate}}" name="s" formControlName="lastName">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="mb-3">
                                        <label class="form-label">Your Email <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                            <input type="email" class="form-control ps-5" placeholder="Email" name="email" formControlName="username">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="mb-3">
                                        <label class="form-label"> {{'SIGNUP.PASSWORD' | translate}} <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="key" class="fea icon-sm icons"></i-feather>
                                            <input type="password" class="form-control ps-5" placeholder="{{'SIGNUP.PASSWORD' | translate}}" formControlName="password">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="mb-3">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="terms">
                                            <label class="form-check-label" for="flexCheckDefault"> {{'SIGNUP.ACCEPT' | translate}} <a routerLink="/page-terms"
                                            class="text-primary"> {{'SIGNUP.TERMENISICONDITII' | translate}} </a></label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="d-grid">
                                        <button [disabled]="form.invalid" type="submit" class="btn btn-primary">{{'SIGNUP.CREATEACC' | translate}}</button>
                                    </div>
                                </div>

                                <div class="mx-auto">
                                    <h6 class="text-center mb-0 mt-3"><small class="text-dark me-2"> {{'SIGNUP.ALREADYHAVEACC' | translate}} </small>
                                        <a routerLink="/login" class="text-dark fw-bold"> {{'SIGNUP.LOGIN' | translate}} </a>
                                    </h6>
                                </div>
                            </div>
                            <div class="alert alert-warning" *ngIf="errorMessage">
                                {{'SIGNUP.SIGNUPFAILED' | translate}} <br/>
                            </div>
                        </form>
                    </div>
            </div>
        </div>
    </div>
</section>
