<section class="d-table w-100" id="home">
    <div class="container">
        <div class="row">
            <div class="">
                <div class="card public-prof border-0 d-flex bg-soft-primary" style="z-index: 1;">
                    <div id="textbox">
                        <p class="alignleft"></p>
                        <p class="aligncenter pt-3">
                            <button routerLink="/profile/settings" type="button" class="btn btn-outline-primary position-relative">
                            <i class="uil uil-cog"></i>
                            </button>
                            <button (click)="logoutConfirmation()" type="button" class="btn btn-outline-primary position-relative">
                                <i class="uil uil-signout"></i>
                            </button>
                        </p>
                        <p class="alignright pt-3">
                            <img style="height: 20px; cursor: pointer"
                                 src="../../../assets/images/romania2.svg" class="avatar avatar-small"
                                 (click)="useLanguage('de')" alt="">
                            <img style="height: 20px; cursor: pointer"
                                 src="../../../assets/images/united-kingdom2.svg" class="avatar avatar-small"
                                 (click)="useLanguage('en')" alt="">
                        </p>
                    </div>
                    <div style="clear: both;"></div>
                </div>
            </div>
        </div>
    </div>
</section>
