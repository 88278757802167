import { AfterContentInit, Component, OnInit } from '@angular/core';
import { LocalityKm, OrdersDetails } from '../../model/api-service.interface';
import { AdminOrderService } from '../../services/admin/admin.order.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrderService } from '../../services/site/order.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit, AfterContentInit {
  constructor(private fb: FormBuilder, private readonly api: AdminOrderService,
              private readonly orderService: OrderService, private modalService: NgbModal) { }
  orderData: OrdersDetails[] = [];
  cost: number;
  costCurrency: string;
  verifyPackage: boolean;
  saturdayDelivery: boolean;
  paymentAt: string;
  packages: any;
  plataType: string;
  editOrderForm: FormGroup = this.fb.group({
    collectingCounty: ['', [ Validators.required]],
    collectingLocality: ['', [ Validators.required]],
    collectingAdrress: ['', [ Validators.required]],
    expeditorName: ['', [ Validators.required]],
    expeditorPhone: ['', [ Validators.required, Validators.minLength(10)]],
    expeditorEmail: ['', [ Validators.required, Validators.email]],
    deliveryCounty: ['', [ Validators.required]],
    deliveryLocality: ['', [ Validators.required]],
    deliveryAdrress: ['', [ Validators.required]],
    destinatarName: ['', [ Validators.required]],
    destinatarPhone: ['', [ Validators.required, Validators.minLength(10)]],
    repaymentValue: [''],
    repaymentType: [''],
    insurancePackage: [''],
    verifyPackage: [''],
    saturdayDelivery: [''],
    comments: [''],
    payExpeditor: [''],
    payDestinatar: ['']
  });
  countiesCollecting: any;
  countiesDestination: any;
  localitiesCollecting: any;
  localitiesDestination: Array<LocalityKm>;

  ngOnInit(): void {
    this.api.getOrders().subscribe((res) => {
      this.orderData = res;
    });
    this.getCollectingCounties();
    this.getDestinationCounties();
  }

  ngAfterContentInit(): void {
    this.getCollectingLocality();
    this.getDestinationLocality();
  }

  open(content: any): void {
    this.modalService.open(content, { centered: true, size: "lg" });
  }

  getCollectingCounties(): void {
    this.orderService.getCounties().subscribe((res) => {
      this.countiesCollecting = res;
    });
  }

  getDestinationCounties(): void {
    this.orderService.getCounties().subscribe((res) => {
      this.countiesDestination = res;
    });
  }

  getCollectingLocality(): void {
    this.orderService.getLocality(this.editOrderForm.controls.collectingCounty.value).subscribe((res) => {
      this.localitiesCollecting = res;
    });
  }

  getDestinationLocality(): void {
    this.orderService.getLocality(this.editOrderForm.controls.deliveryCounty.value).subscribe((res) => {
      this.localitiesDestination = res;
    });
  }

  orderDetails(orderId: number): void{
    this.orderService.orderDetails(orderId).subscribe(res => {
      // console.log('detalii comanda:' + res.packages[0].width);

      this.orderService.getLocality(res.collectingCounty).subscribe((res2) => this.localitiesCollecting = res2);
      this.orderService.getLocality(res.deliveryCounty).subscribe((res2) => this.localitiesDestination = res2);

      this.editOrderForm.patchValue({
        collectingCounty: res.collectingCounty,
        collectingLocality: res.collectingLocality,
        deliveryCounty: res.deliveryCounty,
        deliveryLocality: res.deliveryLocality,
        collectingAdrress: res.collectingAddress,
        expeditorName: res.collectingFirstName + ' ' + res.collectingLastName,
        expeditorPhone: res.collectingPhone,
        expeditorEmail: res.collectingEmail,
        deliveryAdrress: res.deliveryAddress,
        destinatarName: res.deliveryFirstName + ' ' + res.deliveryLastName,
        destinatarPhone: res.deliveryPhone,
        repaymentValue: res.repaymentValue,
        repaymentType: res.repaymentType,
        insurancePackage: res.insurancePackage,
        comments: res.comments,
        verifyPackage: res.verifyPackage
      });

      this.cost = res.cost;
      this.costCurrency = res.costCurrency;
      this.saturdayDelivery = res.saturdayDelivery;
      this.paymentAt = res.paymentAt;
      this.packages = res.packages;
    });
  }

  deleteOrder(orderId: any): void{
    this.orderService.deleteOrder(orderId).subscribe( {
      next: data => {
        window.location.reload();
      },
    });
  }

  editOrder(orderId: number): void{
    const { collectingCounty, collectingLocality, collectingAdrress,
      expeditorName, expeditorPhone, expeditorEmail, deliveryCounty,
      deliveryLocality, deliveryAdrress, destinatarName, destinatarPhone,
      repaymentValue, repaymentType, insurancePackage, verifyPackage,
      saturdayDelivery, comments, payExpeditor } = this.editOrderForm.controls;
    this.orderService.editOrder(orderId, 'RO', collectingCounty.value, collectingLocality.value,
        collectingAdrress.value, expeditorName.value, expeditorName.value, expeditorPhone.value, expeditorEmail.value,
        'RO', deliveryCounty.value,
        deliveryLocality.value, deliveryAdrress.value, destinatarName.value, destinatarName.value,
        destinatarPhone.value, '',
        repaymentValue.value, repaymentType.value, '',  insurancePackage.value, this.verifyPackage,
        this.saturdayDelivery, comments.value, this.plataType,
        this.packages).subscribe({
      next: data => {
        window.location.reload();
      },
      error: err => {
        // this.errorMessageSystem = err.error.message;
      }
    });
  }
}
