import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from '../../services/storage.service';
import { Router } from '@angular/router';
import { SettingsService } from '../../services/admin/settings.service';
import { DeleteComponentModalComponent } from '../../modal/delete-component-modal/delete-component-modal.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  updateProfileDetails: FormGroup = this.fb.group({
    picture: [''],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', Validators.required],
    occupation: [''],
    descrition: [''],
    phoneNumber: [''],
    website: ['']
  });
  pass: FormGroup = this.fb.group({
    oldPassword: ['', [Validators.minLength(6), Validators.required]],
    newPassword: ['', [Validators.minLength(6), Validators.required]]
  });

  public errorMessage: string | undefined;
  public isChangeFailed: boolean | undefined;
  public isChange: boolean | undefined;
  public occupation: any;
  public lastName: any;
  public firstName: any;
  public email: any;
  public description: any;
  public phoneNumber: any;
  public website: any;
  public picture: any;

  constructor(private modalService: NgbModal, private session: StorageService, private router: Router,
              private settingsService: SettingsService, private fb: FormBuilder) { }


  ngOnInit(): void {
    this.settingsService.personalDetails().subscribe(res => {
      this.picture = res.picture;
      this.firstName = res.firstName;
      this.lastName = res.lastName;
      this.email = res.email;
      this.occupation = res.occupation;
      this.description = res.description;
      this.phoneNumber = res.phoneNumber;
      this.website = res.website;
    });
  }

  updateProfDetails(): void{
    const { picture, firstName, lastName, email, occupation, descrition, phoneNumber, website} = this.updateProfileDetails.controls;
    this.settingsService.updatePersonalDetails(
        picture.value, firstName.value, lastName.value,
        email.value, occupation.value, descrition.value, phoneNumber.value,
        website.value).subscribe(res => {
      window.location.reload();
    });
  }

  changePass(): void{
    const { oldPassword, newPassword } = this.pass.controls;
    this.settingsService.changePassword(oldPassword.value, newPassword.value).subscribe({
      next: data => {
        this.isChangeFailed = false;
        this.isChange = true;
        this.router.navigate(['/login']);
        this.session.clean();
      },
      error: err => {
        this.errorMessage = 'Parola a fost schimbata cu succes!';
        this.isChangeFailed = true;
      }
    });
  }

  deleteConfirmation(): void {
    const modalRef = this.modalService.open(DeleteComponentModalComponent);
  }

}
