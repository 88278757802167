<div class="d-flex flex-column h-100 bg-soft-primary">

    <main class="">
        <section>
                <div class="rowr">
                    <div class="border columnl">
                        <app-menu-profile></app-menu-profile>
                    </div>
                    <div class="columnr">
                        <div class="rowr">
                            <app-header-profile> </app-header-profile>
                        </div>
                        <div class="rowr pt-4">
                        <router-outlet (activate)="onActivate($event)"></router-outlet>
                        </div>
                    </div>
                </div>
        </section>
    </main>

<app-footer-profile [footerVariant]="footerClass" [hideFooter]="hideFooter"
                    class="footer box mt-auto py-3 bg-primary">
</app-footer-profile>
</div>
