import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CostPayload, InternationalCost } from '../../model/api-service.interface.js';
import { environment } from '../../../environments/environment';

const ROOT_URL = environment.baseUrl + '/api';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true
};

@Injectable({
    providedIn: 'root',
})
export class OrderService {
    constructor(private http: HttpClient) {}

    getCounties(): Observable<any>{
        return this.http.get(ROOT_URL + '/counties');
    }

    getLocality(county: string): Observable<any> {
        return this.http.get(ROOT_URL + '/locality?county=' + county);
    }

    getcountRies(): Observable<any>{
        return this.http.get(ROOT_URL + '/countries');
    }

    postCost(cost: CostPayload): Observable<any>{
        return this.http.post(ROOT_URL + '/cost', cost);
    }

    postInternationalCost(costInternational: InternationalCost): Observable<any>{
        return this.http.post(ROOT_URL + '/costInternational', costInternational);
    }

    orderDetails(orderId: number): Observable<any>{
        return this.http.get(ROOT_URL + '/admin/order/' + orderId, httpOptions);
    }

    addOrderDetails( collectingCountry: string, collectingCounty: string, collectingLocality: string,
                     collectingAddress: string, collectingFirstName: string, collectingLastName: string,
                     collectingPhone: string, collectingEmail: string, deliveryCountry: string,
                     deliveryCounty: string, deliveryLocality: string,
                     deliveryAddress: string, deliveryFirstName: string, deliveryLastName: string,
                     deliveryPhone: string, deliveryEmail: string, repaymentValue: number,
                     repaymentType: string,  iban: string, insurancePackage: number, verifyPackage: boolean,
                     saturdayDelivery: boolean, paymentAt: string, packageType: string|undefined,
                     comments: string,  packages: any[],
                     ): Observable<any> {
        return this.http.post(ROOT_URL + '/order', {  collectingCountry, collectingCounty, collectingLocality,
                collectingAddress, collectingFirstName, collectingLastName, collectingPhone,
                collectingEmail, deliveryCountry, deliveryCounty, deliveryLocality, deliveryAddress,
                deliveryFirstName, deliveryLastName, deliveryPhone, deliveryEmail, repaymentValue,
                repaymentType, iban, insurancePackage, verifyPackage, saturdayDelivery,
                comments, paymentAt, packageType, packages },
            httpOptions
        );
    }

    editOrder(orderId: number, collectingCountry: string, collectingCounty: string,
              collectingLocality: string, collectingAddress: string, collectingFirstName: string,
              collectingLastName: string, collectingPhone: string, collectingEmail: string,
              deliveryCountry: string, deliveryCounty: string, deliveryLocality: string,
              deliveryAddress: string, deliveryFirstName: string, deliveryLastName: string,
              deliveryPhone: string, deliveryEmail: string, repaymentValue: number,
              repaymentType: string, iban: string, insurancePackage: number, verifyPackage: boolean,
              saturdayDelivery: boolean, comments: string, paymentAt: string, packages: any[]): Observable<any>{
        return this.http.put(ROOT_URL + '/admin/order/' + orderId, {
                collectingCountry, collectingCounty,
                collectingLocality, collectingAddress, collectingFirstName,
                collectingLastName, collectingPhone, collectingEmail,
                deliveryCountry, deliveryCounty, deliveryLocality,
                deliveryAddress, deliveryFirstName, deliveryLastName,
                deliveryPhone, deliveryEmail, repaymentValue,
                repaymentType, iban, insurancePackage, verifyPackage,
                saturdayDelivery, comments, paymentAt, packages
            },
            httpOptions
        );
    }

    deleteOrder(orderId: number): Observable<any>{
        return this.http.delete(
            ROOT_URL + '/admin/order/' + orderId,
            httpOptions,
        );
    }

}
