import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({providedIn: 'root'})
export class OrderStatusService {
    readonly ROOT_URL = environment.baseUrl + '/api';

    constructor(private readonly http: HttpClient) {
    }

    getLastStatus(awb: string): Observable<any> {
        return this.http.get(this.ROOT_URL + '/status?awb=' + awb);
    }

    findDate(awb: string): Observable<any>{
        return this.http.get(this.ROOT_URL + '/date?awb=' + awb);
    }

}
