import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

const ROOT_URL = environment.baseUrl + '/api/admin/settings/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private http: HttpClient) {}

  personalDetails(): Observable<any>{
    return this.http.get(
        ROOT_URL + 'personalDetails',
      httpOptions,
    );
  }

  updatePersonalDetails(picture: string, firstName: string, lastName: string,
                        email: string, occupation: string, description: string,
                        phoneNumber: string, website: string): Observable<any>{
    return this.http.put(
        ROOT_URL + 'personalDetails',
      {
        picture, firstName, lastName, email, occupation, description,
        phoneNumber, website
      },
      httpOptions,
    );
  }

  changePassword(oldPassword: string, newPassword: string): Observable<any>{
    return this.http.put(
        ROOT_URL + 'changePassword',
      {
        oldPassword,
        newPassword
      },
      httpOptions,
    );
  }

  deleteAccount(): Observable<any>{
    return this.http.delete(
        ROOT_URL + 'deleteAccount',
      httpOptions,
    );
  }

}
