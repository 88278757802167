import { Component, OnInit } from '@angular/core';
import { UsersDetails } from '../../model/api-service.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/admin/user.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
  isAdd: boolean | undefined;
  errorMessage: boolean | undefined;
  userData: UsersDetails[] = [];
  addUserForm: FormGroup = this.fb.group({
    username: ['', [ Validators.required, Validators.email]],
    firstName: ['', [ Validators.required, Validators.minLength(3)]],
    lastName: ['', [ Validators.required, Validators.minLength(3)]],
    phone: ['', [ Validators.required, Validators.minLength(10)]],
    counties: ['', [ Validators.required]],
    status: ['', [ Validators.required]],
    role: ['', [ Validators.required]]
  });
  public isAdmin: boolean;
  constructor(private userService: UserService, public activeModal: NgbActiveModal, private fb: FormBuilder,
              private storageService: StorageService ) { }

  ngOnInit(): void {
    if (this.storageService.getUser().role === 'ADMIN'){
      this.isAdmin = true;
    }
  }

  addUser(): void{
    const {username, firstName, lastName, phone, counties,
            status, role } = this.addUserForm.controls;
    this.userService.addUser(username.value, firstName.value, lastName.value,
        phone.value, counties.value, status.value, role.value).subscribe({
      next: data => {
        this.isAdd = true;
        setTimeout(() => {
          window.location.reload();
        }, 700);
        this.activeModal.dismiss();
      },
      error: err => {
        this.errorMessage = true;
      }
    });
  }

}
