<div class="container">
    <div class="row" id="counter">
            <div class="row">
                <div class="col-sm">
                    <div class="card counter-box border-0 bg-secondary bg-gradient shadow text-center rounded">
                        <div class="card-body py-5">
                            <h2 class="mb-0"><span class="counter-value"> 40 </span></h2>
                            <h5 class="counter-head mb-0">Cars</h5>
                        </div>
                    </div>
                </div>

                <div class="col-sm">
                    <div class="card counter-box border-0 bg-primary bg-gradient shadow text-center rounded">
                        <div class="card-body py-5">
                            <h2 class="text-light title-dark mb-0"><span class="counter-value">10</span></h2>
                            <h5 class="counter-head mb-0 title-dark text-light">Users</h5>
                        </div>
                    </div>
                </div>

                <div class="col-sm">
                    <div class="card counter-box border-0 bg-success bg-gradient shadow text-center rounded">
                        <div class="card-body py-5">
                            <h2 class="text-light title-dark mb-0"><span class="counter-value"></span>1000</h2>
                            <h5 class="counter-head mb-0 title-dark text-light">RON</h5>
                        </div>
                    </div>
                </div>

                <div class="col-sm">
                    <div class="card counter-box border-0 bg-warning bg-gradient shadow text-center rounded">
                        <div class="card-body py-5">
                            <h2 class="mb-0"><span class="counter-value"></span>Problems</h2>
                            <h5 class="counter-head mb-0">10</h5>
                        </div>
                    </div>
                </div>
            </div>

        <div class="pt-4 row">
            <div class="col-sm">
                <div class="card counter-box border-0 bg-warning bg-gradient shadow text-center rounded">
                    <div class="card-body py-5">
                        <h2 class="mb-0"><span class="counter-value" >40</span></h2>
                        <h5 class="counter-head mb-0">Cars</h5>
                    </div>
                </div>
            </div>

            <div class="col-sm">
                <div class="card counter-box border-0  bg-success bg-gradient shadow text-center rounded">
                    <div class="card-body py-5">
                        <h2 class="text-light title-dark mb-0"><span class="counter-value">10</span></h2>
                        <h5 class="counter-head mb-0 title-dark text-light">Users</h5>
                    </div>
                </div>
            </div>

            <div class="col-sm">
                <div class="card counter-box border-0 bg-primary bg-gradient shadow text-center rounded">
                    <div class="card-body py-5">
                        <h2 class="text-light title-dark mb-0"><span class="counter-value"></span>1000</h2>
                        <h5 class="counter-head mb-0 title-dark text-light">RON</h5>
                    </div>
                </div>
            </div>

            <div class="col-sm">
                <div class="card counter-box border-0  bg-secondary bg-gradient shadow text-center rounded">
                    <div class="card-body py-5">
                        <h2 class="mb-0"><span class="counter-value"></span>Problems</h2>
                        <h5 class="counter-head mb-0">10</h5>
                    </div>
                </div>
            </div>
        </div>

        <div class="pt-4 row">
            <div class="col-sm">
                <div class="card counter-box border-0 bg-secondary bg-gradient shadow text-center rounded">
                    <div class="card-body py-5">
                        <h2 class="mb-0"><span class="counter-value" >40</span></h2>
                        <h5 class="counter-head mb-0">Cars</h5>
                    </div>
                </div>
            </div>

            <div class="col-sm">
                <div class="card counter-box border-0 bg-primary bg-gradient shadow text-center rounded">
                    <div class="card-body py-5">
                        <h2 class="text-light title-dark mb-0"><span class="counter-value">10</span></h2>
                        <h5 class="counter-head mb-0 title-dark text-light">Users</h5>
                    </div>
                </div>
            </div>

            <div class="col-sm">
                <div class="card counter-box border-0 bg-success bg-gradient shadow text-center rounded">
                    <div class="card-body py-5">
                        <h2 class="text-light title-dark mb-0"><span class="counter-value"></span>1000</h2>
                        <h5 class="counter-head mb-0 title-dark text-light">RON</h5>
                    </div>
                </div>
            </div>

            <div class="col-sm">
                <div class="card counter-box border-0 bg-warning bg-gradient shadow text-center rounded">
                    <div class="card-body py-5">
                        <h2 class="mb-0"><span class="counter-value"></span>Problems</h2>
                        <h5 class="counter-head mb-0">10</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
