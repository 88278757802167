import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-menu-profile',
  templateUrl: './menu-profile.component.html',
  styleUrls: ['./menu-profile.component.css']
})
export class MenuProfileComponent implements OnInit {
  currentRoute: string;
  isAdminOrManager: boolean;
  constructor(private router: Router, private storageService: StorageService) {
    this.currentRoute = '';
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Hide progress spinner or progress bar
        this.currentRoute = event.url;
      }
    });

  }

  ngOnInit(): void {
    if (this.storageService.getUser().role === 'ADMIN' || this.storageService.getUser().role === 'MANAGER'){
      this.isAdminOrManager = true;
    }
  }

}
