<section class="bg-home bg-circle-gradiant d-flex align-items-center">
    <div class="bg-overlay bg-overlay-white"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-5 col-md-8">
                <div class="card-body">
                    <h4 class="card-title text-center">{{'FORGOTPASS.RECOVER' | translate}}</h4>

                    <form class="login-form mt-4" name="form" [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-lg-12">
                                <p class="text-muted">{{'FORGOTPASS.INFOMESSAGE' | translate}}</p>
                                <div class="mb-3">
                                    <label  for="email" class="form-label">{{'FORGOTPASS.EMAIL' | translate}}<span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                        <input
                                                type="email"
                                                name="email"
                                                formControlName="email"
                                                id="email"
                                                class="form-control ps-5" placeholder="{{'FORGOTPASS.PLACEHOLDER' | translate}}">
                                        <div *ngIf="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched)" class="alert">
                                            <div class="alert alert-danger" *ngIf="form.controls['email'].errors['required']">
                                                {{'FORGOTPASS.REQUIRED' | translate}}
                                            </div>
                                            <div class="alert alert-danger" *ngIf="form.controls['email'].errors['email']">
                                                {{'FORGOTPASS.INCORECTEMAIL' | translate}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->
                            <div class="col-lg-12">
                                <div class="d-grid">
                                    <button class="btn btn-primary" [disabled]="form.invalid">{{'FORGOTPASS.SEND' | translate}}</button>
                                </div>
                            </div>
                            <!--end col-->
                            <div class="mx-auto">
                                <p class="mb-0 mt-3"><small class="text-dark me-2">{{'FORGOTPASS.REMEMBER' | translate}}</small> <a
                                        routerLink="/login" class="text-dark fw-bold">{{'FORGOTPASS.LOGIN' | translate}}</a></p>
                            </div>
                        </div>
                    </form>
                    <div class="alert alert-success" *ngIf="isMessage" >
                        {{'FORGOTPASS.SUCCESMESSAGE' | translate}}
                    </div>
                    <div class="alert alert-danger" *ngIf="errorMessage" >
                        {{'FORGOTPASS.INCORECTEMAIL' | translate}}
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
