import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../../services/site/login.service';
import { Router } from '@angular/router';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  form: FormGroup = this.fb.group({
    username: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    firstName: ['', [Validators.required, Validators.minLength(3)]],
    lastName: ['', [Validators.required, Validators.minLength(3)]],
    terms: ['', [Validators.requiredTrue]],
  });
  errorMessage: boolean;

  constructor(private fb: FormBuilder, private api: LoginService,
              private router: Router, private storageService: StorageService) { }

  ngOnInit(): void {
  }

  onSubmit(): void{
    const {username, password, firstName, lastName} = this.form.controls;

    this.api.signup(username.value, password.value, firstName.value, lastName.value, false, false,
        true, 'USER').subscribe({
      next: data => {
        this.storageService.saveUser(data);
        this.router.navigate(['/profile']);
      },
      error: err => {
        this.errorMessage = true;
      }
    });
  }

}
