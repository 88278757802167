<section>
    <div class="content">
        <div class="modal-header">
            <div class="p-4"><h4 class="title mb-0"> {{'CONTACT.CONTACTR' | translate}} 👋 </h4></div>
            <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <form [formGroup]="contactprofileForm" (ngSubmit)="onSubmit()">
                <div class="col-md-12">
                    <div class="mb-3">
                        <label class="form-label"> {{'CONTACT.NAME' | translate}} <span class="text-danger">*</span></label>
                        <div class="form-icon position-relative">
                            <i-feather name="user" class="fea icon-sm icons"></i-feather>
                            <input name="name" id="name" type="text" class="form-control ps-5"
                                   placeholder="{{'CONTACT.NAMEPLACEH' | translate}}" formControlName="name">
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="mb-3">
                        <label class="form-label">Email <span class="text-danger">*</span></label>
                        <div class="form-icon position-relative">
                            <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                            <input name="email" id="email" type="email" class="form-control ps-5"
                                   placeholder="Email-ul tau:" formControlName="email">
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="mb-3">
                        <label class="form-label">{{'CONTACT.PHONE' | translate}}<span class="text-danger">*</span></label>
                        <div class="form-icon position-relative">
                            <i-feather name="phone" class="fea icon-sm icons"></i-feather>
                            <input name="subject" id="phone" class="form-control ps-5" placeholder="{{'CONTACT.PHONE' | translate}}" formControlName="mobile">
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="mb-3">
                        <label class="form-label"> {{'CONTACT.SUBJECT' | translate}} <span class="text-danger">*</span></label>
                        <div class="form-icon position-relative">
                            <i-feather name="book" class="fea icon-sm icons"></i-feather>
                            <input name="subject" id="subject" class="form-control ps-5" placeholder="{{'CONTACT.SUBJECT' | translate}}" formControlName="subject">
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="mb-3">
                        <label class="form-label">{{'CONTACT.MESSAGE' | translate}}<span class="text-danger">*</span></label>
                        <div class="form-icon position-relative">
                            <i-feather name="message-circle" class="fea icon-sm icons"></i-feather>
                            <textarea name="comments" id="comments" rows="4" class="form-control ps-5"
                                      placeholder="{{'CONTACT.MESSAGE' | translate}}" formControlName="message"></textarea>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <h6 style="color: red">{{message}} </h6>
                    <button type="submit" class="btn btn-outline-dark" [disabled]="contactprofileForm.invalid">{{'CONTACT.SEND' | translate}}</button>
                </div>
            </form>
        </div>
    </div>
</section>
