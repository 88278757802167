<div class="back-to-home rounded d-none d-sm-block">
    <a routerLink="" class="btn btn-icon btn-soft-primary">
        <i-feather name="home" class="icons"></i-feather>
    </a>
</div>

<section class="bg-home d-flex align-items-center">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12 text-center">
                <img src="../../../assets/images/404.svg" class="img-fluid" alt="">
                <div class="text-uppercase mt-4 display-3"> Oh! no </div>
                <div class="text-capitalize text-dark mb-4 error-page">Page Not Found</div>
                <p class="text-muted para-desc mx-auto"></p>
            </div>
        </div>
    </div>
</section>
