import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'}),
    withCredentials: true
};

@Injectable({providedIn: 'root'})
export class AdminOrderService {
    readonly ROOT_URL = environment.baseUrl + '/api/admin/order';

    constructor(private readonly http: HttpClient) {
    }

    getOrders(): Observable<any> {
        return this.http.get(this.ROOT_URL + '/orders', httpOptions);
    }
}
