<section class="bg-home bg-circle-gradiant d-flex align-items-center">
    <div class="bg-overlay bg-overlay-white"></div>
    <div class="container">
        <div class="row justify-content-center">

            <div class="col-lg-5 col-md-8">
                <div class="card-body">
                    <h4 class="card-title text-center"> LOGIN </h4>
                    <form class="login-form mt-4"
                          name="form"
                          [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="mb-3">
                                    <label class="form-label"> Email <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                        <input
                                                type="email"
                                                class="form-control ps-5"
                                                name="email"
                                                formControlName="email"
                                                placeholder="Email">
                                        <div *ngIf="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched)" class="alert">
                                            <div class="alert alert-danger" *ngIf="form.controls['email'].errors['required']">
                                                {{'LOGIN.EMAILREQUIRED' | translate}}
                                            </div>
                                            <div class="alert alert-danger" *ngIf="form.controls['email'].errors['email']">
                                                {{'LOGIN.EMAILINCORECT' | translate}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="mb-3">
                                    <label class="form-label"> {{'LOGIN.PASSWORD' | translate}} <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <i-feather name="key" class="fea icon-sm icons"></i-feather>
                                        <input
                                                type="password"
                                                class="form-control ps-5"
                                                name="password"
                                                formControlName="password"
                                                placeholder="{{'LOGIN.PASSWORD' | translate}}">
                                        <div *ngIf="form.controls['password'].invalid && (form.controls['password'].dirty || form.controls['password'].touched)" class="alert">
                                            <div class="alert alert-danger" *ngIf="form.controls['password'].errors['required']">
                                                {{'LOGIN.PASSWORDREQUIRED' | translate}}
                                            </div>
                                            <div class="alert alert-danger" *ngIf="form.controls['password'].errors['minlength']">
                                                {{'LOGIN.PASSWORDMIN6' | translate}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           

                            <div class="col-lg-12">
                                <div class="d-flex justify-content-between">
                                    <div class="mb-3 alert alert-success">
                                        <a href="" routerLink="/signup"
                                           class="text-white fw-bold">
                                            {{'LOGIN.CREATEACC' | translate}}
                                        </a>
                                    </div>
                                    <div class="mb-3 alert alert-secondary">
                                        <a href="" routerLink="/forgotPassword"
                                           class="text-white fw-bold">
                                            {{'LOGIN.FORGOTPASS' | translate}}
                                        </a>
                                    </div>
                                </div>
                            </div>
                           

                            <div class="col-lg-12 mb-0">
                                <div class="d-grid">
                                    <button class="btn btn-primary">LOGIN</button>
                                </div>
                            </div>

                        </div>
                        <div class="alert alert-danger" *ngIf="isLoginFailed">
                            {{'LOGIN.LOGINFAILED' | translate}} <br/>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
