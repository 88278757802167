import { Component, OnInit } from '@angular/core';
import {ConstantsService} from "../../services/constants.service";

@Component({
  selector: 'app-template-newsletter',
  templateUrl: './template-newsletter.component.html',
  styleUrls: ['./template-newsletter.component.css']
})
export class TemplateNewsletterComponent implements OnInit {
  year = new Date().getFullYear();
  siteName = '';
  constructor() { }

  ngOnInit(): void {
    this.siteName = ConstantsService.siteName;
  }

}
