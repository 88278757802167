import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingsService } from '../../services/admin/settings.service';
import { StorageService } from '../../services/storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delete-component-modal',
  templateUrl: './delete-component-modal.component.html',
  styleUrls: ['./delete-component-modal.component.css']
})
export class DeleteComponentModalComponent implements OnInit {
  public isDeleteFailed: boolean | undefined;
  public isDelete: boolean | undefined;
  public errorMessage: string | undefined;
  constructor(public activeModal: NgbActiveModal, private settingsService: SettingsService,
              private session: StorageService, private router: Router, private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  deleteAcc(): void{
    this.settingsService.deleteAccount().subscribe({
      next: data => {
        this.isDeleteFailed = false;
        this.isDelete = true;
        this.router.navigate(['/']);
        this.session.clean();
        this.modalService.dismissAll();
      },
      error: err => {
        this.errorMessage = 'Contul nu a fost sters!';
        this.isDeleteFailed = true;
      }
    });
  }

}
