import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../services/site/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmedValidator } from '../../services/ConfirmedValidator';
import { Location } from '@angular/common';
@Component({
  selector: 'app-set-new-password',
  templateUrl: './set-new-password.component.html',
  styleUrls: ['./set-new-password.component.css']
})
export class SetNewPasswordComponent implements OnInit {
  param: any;
  recoveryId = '';
  isMessage = false;
  form: FormGroup = new FormGroup({});
  constructor(public translate: TranslateService, private api: LoginService,
              private fb: FormBuilder, private route: ActivatedRoute,
              private router: Router, private location: Location) {
    this.form = fb.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirm_password: ['', [Validators.required]]
    }, {
      validator: ConfirmedValidator('password', 'confirm_password')
    });
  }

  ngOnInit(): void {
    this.param = this.route.queryParams
        .subscribe(params => {
          this.recoveryId = params.r;
        });

    const [path, query] = this.location.path().split('?');
    this.location.replaceState(path, '');

    // const [path, query] = this.location.path().split('?');
    // const params = new HttpParams({ fromString: query });
    // this.location.replaceState(path, params.delete('r').toString());
  }

  onSubmit(): void{
    const {confirm_password} = this.form.controls;
    this.api.updatePassword(this.recoveryId, confirm_password.value).subscribe((res) => {

      this.isMessage = true;
      this.form.reset();
    });
    setTimeout(() => {
      this.router.navigate(['/login']);
    }, 3000);
  }

}
