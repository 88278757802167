<!-- TEMPLATE FOR NEWSLETTER-->
<section style="align-items: center; padding: 150px 0;">
    <div class="container">
        <div class="row" style="justify-content: center;">
            <div class="col-lg-6 col-md-8">
                <table
                        style="box-sizing: border-box; width: 100%; border-radius: 6px; overflow: hidden; background-color: #fff; box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);">
                    <thead>
                    <tr
                            style="background-color: #2f55d4; padding: 3px 0; line-height: 68px; text-align: center; color: #fff; font-size: 24px; font-weight: 700; letter-spacing: 1px;">
                        <th scope="col"><img src="assets/images/logo.png" height="40" alt=""></th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                        <td style="padding: 48px 24px 0; color: #161c2d; font-size: 18px; font-weight: 600;">
                            Hello, nume
                        </td>
                    </tr>
                    <tr>
                        <td style="padding: 15px 24px 15px; color: #8492a6;">
                            V-ati abonat cu succes la Newsletter! <br>
                            Vă mulțumim că sunteți alături de noi!
                        </td>
                    </tr>

                    <tr>
                        <td style="padding: 15px 24px;">
                            <a href="javascript:void(0)" class="btn-primary"
                               style="padding: 8px 20px; outline: none; text-decoration: none; font-size: 16px; letter-spacing: 0.5px; transition: all 0.3s; font-weight: 600; border-radius: 6px;"> Trimite un pachet </a>
                        </td>
                    </tr>


                    <tr>
                        <td style="padding: 15px 24px 15px; color: #8492a6;">
                            Cargo Express <br> Support Team
                        </td>
                    </tr>

                    <tr>
                        <td
                                style="padding: 16px 8px; color: #8492a6; background-color: #f8f9fc; text-align: center;">
                            © {{year}} Cargo Express.
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end TEMPLATE FOR NEWSLETTER-->


<!--TEMPLATE FOR CONTACT-->
<title> Solicitarea ta a fost înregistrată  </title>
<section style="align-items: center; padding: 150px 0;">
    <div class="container">
        <div class="row" style="justify-content: center;">
            <div class="col-lg-6 col-md-8">
                <table style="box-sizing: border-box; width: 100%; border-radius: 6px; overflow: hidden; background-color: #fff; box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);">
                    <thead>
                    <tr style="background-color: #2f55d4; padding: 3px 0; line-height: 68px; text-align: center; color: #fff; font-size: 24px; font-weight: 700; letter-spacing: 1px;">
                        <th scope="col"><img src="../../../assets/images/logo.png" height="40" alt=""></th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                        <td style="padding: 48px 24px 0; color: #161c2d; font-size: 18px; font-weight: 600;">
                            Hello, nume
                        </td>
                    </tr>
                    <tr>
                        <td style="padding: 15px 24px 15px; color: #8492a6;">
                            Solicitarea ta a fost înregistrată cu nr...

                            Îți mulțumim că ne-ai scris, părerea ta este foarte importantă pentru noi. Ne grăbim să găsim cea mai bună variantă,
                            in cel mult 48 de ore revenim cu vești prin e-mail sau telefon despre situația semnalată. Iti multumim pentru intelegere!
                        </td>
                    </tr>

                    <tr>
                        <td style="padding: 15px 24px;">
                            <a href="javascript:void(0)" class="btn-primary"
                               style="padding: 8px 20px; outline: none; text-decoration: none; font-size: 16px; letter-spacing: 0.5px; transition: all 0.3s; font-weight: 600; border-radius: 6px;"> Mergi la site </a>
                        </td>
                    </tr>


                    <tr>
                        <td style="padding: 15px 24px 15px; color: #8492a6;">
                            Cargo Express <br> Support Team
                        </td>
                    </tr>

                    <tr>
                        <td
                                style="padding: 16px 8px; color: #8492a6; background-color: #f8f9fc; text-align: center;">
                            © {{year}} Cargo Express.
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end TEMPLATE FOR CONTACT-->

<!--TEMPLATE FOR ORDER PLACEMENT-->
<title> Comanda nrComanda  </title>
<section style="align-items: center; padding: 150px 0;">
    <div class="container">
        <div class="row" style="justify-content: center;">
            <div class="col-lg-6 col-md-8">
                <table
                        style="box-sizing: border-box; width: 100%; border-radius: 6px; overflow: hidden; background-color: #fff; box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);">
                    <thead>
                    <tr
                            style="background-color: #2f55d4; padding: 3px 0; line-height: 68px; text-align: center; color: #fff; font-size: 24px; font-weight: 700; letter-spacing: 1px;">
                        <th scope="col"><img src="../../../assets/images/logo.png" height="40" alt=""></th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                        <td style="padding: 48px 24px 0; color: #161c2d; font-size: 18px; font-weight: 600;">
                            Hello, nume
                        </td>
                    </tr>
                    <tr>
                        <td style="padding: 15px 24px 15px; color: #8492a6;">
                            &nbsp;&nbsp;&nbsp; Iti multumim pentru comanda ta pe <a href="http://localhost:4200/">courierexpress.ro</a>. <br>
                            &nbsp;&nbsp;&nbsp; Numarul comenzii, plasate in data de DATE, este <span style="color: #0d6efd"> nrComanda</span>. <br>
                            &nbsp;&nbsp;&nbsp; Daca sunteti inregistrat pe <a href="http://localhost:4200/">courierexpress.ro</a> puteti vedea detaliile comenzii in contul dvs. <a href="http://localhost:4200/">aici</a>. <br>
                            &nbsp;&nbsp;&nbsp; Puteti urmari comanda pe pagina principala a site-ului <a href="http://localhost:4200/">courierexpress.ro</a> la sectiunea "Verifica AWB".
                        </td>
                    </tr>

                    <tr>
                        <td style="padding: 24px 24px 0;">
                            <table style="border: none;">
                                <tbody>
                                <tr>
                                    <td style="padding-bottom: 8px">Invoice No.:</td> &nbsp; <span style="color: #0d6efd"> nrFactura</span>
                                </tr>
                                <tr>
                                    <td style=" padding-bottom: 8px;">Name:</td> Nume Expeditor
                                </tr>
                                <tr>
                                    <td style=" padding-bottom: 8px;">Address:</td>Adresa........
                                </tr>
                                <tr>
                                    <td style="padding-bottom: 8px;">Date:</td>DATE
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>

                    <tr>
                        <td style="padding: 24px;">
                            <div
                                    style="display: grid; overflow-x: auto; -webkit-overflow-scrolling: touch; border-radius: 6px; box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);">
                                <table cellpadding="0" cellspacing="0">
                                    <thead class="bg-light">
                                    <tr>
                                        <th scope="col"
                                            style="text-align: left; vertical-align: bottom; border: 1px solid #dee2e6; padding: 12px; width: 200px;">
                                            Detaliile comenzii</th>
                                        <th scope="col"
                                            style="text-align: left; vertical-align: bottom; border: 1px solid #dee2e6; padding: 12px; width: 200px;">
                                            Adresa de ridicare</th>
                                        <th scope="col"
                                            style="text-align: left; vertical-align: bottom; border: 1px solid #dee2e6; padding: 12px; width: 200px;">
                                            Adresa de livrare</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td style="text-align: left; padding: 10px; border: 1px solid #dee2e6;">ID comanda: <span style="color: #0d6efd"> nrComanda</span> <br>
                                            Data adaugarii: DATE
                                            Metoda de plata: Plata ramburs la curier
                                        </td>
                                        <td style="text-align: left; padding: 12px; border: 1px solid #dee2e6;"> 08767899 <br>
                                            Adresa 1
                                            </td>
                                        <td style="text-align: left; padding: 12px; border: 1px solid #dee2e6;">09877897 <br>
                                            Adresa 2
                                        </td>
                                    </tr>

                                    <tr
                                            style="background-color: rgba(47, 85, 212, 0.2); color: #2f55d4; overflow-x: hidden;">
                                        <th scope="row"
                                            style="text-align: left; padding: 12px; border-top: 1px solid rgba(47, 85, 212, 0.2);">
                                            Total cost</th>
                                        <td colspan="2"
                                            style="text-align: end; font-weight: 700; font-size: 18px; padding: 12px; border-top: 1px solid rgba(47, 85, 212, 0.2);">
                                            X lei</td>
                                    </tr>
                                    </tbody>
                                </table>

                                <table class="mt-3" cellpadding="0" cellspacing="0">
<!--                                    <colgroup span="2"></colgroup>-->
<!--                                    <colgroup span="2"></colgroup>-->
                                    <thead class="bg-light">
                                    <tr>
                                        <td style="text-align: left; vertical-align: bottom; border: 1px solid #dee2e6; padding: 12px; width: 200px;" rowspan="1"></td>
                                        <th style="text-align: left; vertical-align: bottom; border: 1px solid #dee2e6; padding: 12px; width: 200px;" colspan="6" scope="colgroup">Detalii pachete</th>
                                    </tr>
                                    <tr>
                                        <th style="text-align: left; vertical-align: bottom; border: 1px solid #dee2e6; padding: 12px; width: 200px;" scope="col">Nr.</th>
                                        <th style="text-align: left; vertical-align: bottom; border: 1px solid #dee2e6; padding: 12px; width: 200px;" scope="col">Type</th>
                                        <th style="text-align: left; vertical-align: bottom; border: 1px solid #dee2e6; padding: 12px; width: 200px;" scope="col">Amount</th>
                                        <th style="text-align: left; vertical-align: bottom; border: 1px solid #dee2e6; padding: 12px; width: 200px;" scope="col">Length</th>
                                        <th style="text-align: left; vertical-align: bottom; border: 1px solid #dee2e6; padding: 12px; width: 200px;" scope="col">Width</th>
                                        <th style="text-align: left; vertical-align: bottom; border: 1px solid #dee2e6; padding: 12px; width: 200px;" scope="col">Height</th>
                                        <th style="text-align: left; vertical-align: bottom; border: 1px solid #dee2e6; padding: 12px; width: 200px;" scope="col">Weight</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <th  style="text-align: left; vertical-align: bottom; border: 1px solid #dee2e6; padding: 12px; width: 200px;" scope="row">1</th>
                                        <td style="text-align: left; vertical-align: bottom; border: 1px solid #dee2e6; padding: 12px; width: 200px;">Pachet</td>
                                        <td style="text-align: left; vertical-align: bottom; border: 1px solid #dee2e6; padding: 12px; width: 200px;">3</td>
                                        <td style="text-align: left; vertical-align: bottom; border: 1px solid #dee2e6; padding: 12px; width: 200px;">10</td>
                                        <td style="text-align: left; vertical-align: bottom; border: 1px solid #dee2e6; padding: 12px; width: 200px;">80</td>
                                        <td style="text-align: left; vertical-align: bottom; border: 1px solid #dee2e6; padding: 12px; width: 200px;">8</td>
                                        <td style="text-align: left; vertical-align: bottom; border: 1px solid #dee2e6; padding: 12px; width: 200px;">8</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td
                                style="padding: 16px 8px; color: #8492a6; background-color: #f8f9fc; text-align: center;">
                            © {{year}} Cargo Express.
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end TEMPLATE FOR ORDER PLACEMENT-->
