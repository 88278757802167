import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactService } from '../../services/site/contact.service';


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent {
  @Input() name;

  constructor(public activeModal: NgbActiveModal, private readonly api: ContactService) {}
  message: string;
  contactprofileForm = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', Validators.compose([
      Validators.required,
      Validators.email
    ])),
    mobile: new FormControl('', Validators.required),
    subject: new FormControl('', Validators.required),
    message: new FormControl('', Validators.required),
  });

  onSubmit(): void {
    this.api.receiveContactEmail(this.contactprofileForm.controls.name.value,
        this.contactprofileForm.controls.email.value, this.contactprofileForm.controls.mobile.value,
        this.contactprofileForm.controls.subject.value, this.contactprofileForm.controls.message.value).subscribe((res) => {
    });
    this.contactprofileForm.controls.name.reset();
    this.contactprofileForm.controls.email.reset();
    this.contactprofileForm.controls.mobile.reset();
    this.contactprofileForm.controls.subject.reset();
    this.contactprofileForm.controls.message.reset();
    this.message = 'Mesaj trimis cu succes!';
  }

}
