import {Component, OnInit, Input, AfterViewInit} from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {FormBuilder} from '@angular/forms';
import {ModalComponent} from '../../modal/contact-modal/modal.component';
import {ConstantsService} from '../../services/constants.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})

/***
 * Header Component
 */
export class HeaderComponent implements OnInit, AfterViewInit {
  telefon = '';
  siteEmail = '';
  siteName = '';
  constructor(private router: Router, private modalService: NgbModal, public translate: TranslateService, private route: ActivatedRoute,
              private formBuilder: FormBuilder) {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this._activateMenuDropdown();
      }
    });
  }

  @Input() navClass: string;
  @Input() buttonList: boolean;
  @Input() sliderTopbar: boolean;
  @Input() isdeveloper: boolean;
  @Input() shopPages: boolean;
  private href: string;
  public ok: boolean;

  checkoutForm = this.formBuilder.group({
    name: '',
    address: ''
  });

  isCondensed = false;

  open(): void {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.name = 'World';
  }

  onSubmit(): void {
    this.checkoutForm.reset();
  }
  ngAfterViewInit(): void {
    this._activateMenuDropdown();

  }

  ngOnInit(): void {
    this.telefon = ConstantsService.telefon;
    this.siteEmail = ConstantsService.email;
    this.siteName = ConstantsService.siteName;
  }

  useLanguage(language: string): void {
    this.translate.use(language);
    localStorage.setItem('lang', language);
  }

  _activateMenuDropdown(): void {
    /**
     * Menu activation reset
     */
    const resetParent = (el) => {
      el.classList.remove('active');
      const parent = el.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.remove('active');
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.remove('active');
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove('active');
            const parent4 = parent3.parentElement;
            if (parent4) {
              const parent5 = parent4.parentElement;
              parent5.classList.remove('active');

            }
          }
        }
      }
    };
    const links = document.getElementsByClassName('nav-link-ref');
    let matchingMenuItem = null;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // reset menu
      resetParent(links[i]);
    }

    for (let i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i]["pathname"]) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add('active');
      const parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add('active');
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add('active');
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add('active');
            const parent4 = parent3.parentElement;
            if (parent4) {
              const parent5 = parent4.parentElement;
              parent5.classList.add('active');

              document.getElementById('navigation').style.display = 'none';
              this.isCondensed = false;
            }
          }
        }
      }
    }
  }

  /**
   * Window scroll method
   */
  // tslint:disable-next-line: typedef
  windowScroll() {
      if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
          document.getElementById('topnavedit').classList.add('nav-sticky');
      } else {
          document.getElementById('topnavedit').classList.remove('nav-sticky');
      }

      if (document.getElementById('back-to-top')) {
          if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
              document.getElementById('back-to-top').style.display = 'inline';
          } else {
              document.getElementById('back-to-top').style.display = 'none';
          }
      }
  }
  /**
   * Toggle menu
   */
  toggleMenu(): void {
    this.isCondensed = !this.isCondensed;
    if (this.isCondensed) {
      document.getElementById('navigation').style.display = 'block';
    } else {
      document.getElementById('navigation').style.display = 'none';
    }
  }

  scroll(elementId: string): void {
    if (this.router.url !== '/') {
      this.router.navigate(['/'], {fragment: elementId});
      return;
    }
    document.getElementById(elementId).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
    window.history.pushState('page2', 'Title', '#' + elementId);
  }

  /**
   * Menu clicked show the submenu
   */
  onMenuClick(event): boolean {
    event.preventDefault();
    const nextEl = event.target.nextSibling.nextSibling;
    if (nextEl && !nextEl.classList.contains('open')) {
      const parentEl = event.target.parentNode;
      if (parentEl) {
        parentEl.classList.remove('open');
      }
      nextEl.classList.add('open');
    } else if (nextEl) {
      nextEl.classList.remove('open');
    }
    return false;
  }

  developerModal(content): void {
    this.modalService.open(content, { size: 'lg', centered: true });
  }
}
