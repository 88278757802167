<div class="container text-center">
    <div class="row align-items-center">
        <div class="col-sm-6">
            <div class="text-sm-start">
                <p style="color: white" class="mb-0">© {{year}}
                    Design with <i class="mdi mdi-heart fw-bold"></i> by <a
                            href="javascript:void(0);" class="text-reset">Fun IT Service</a>.
                </p>
            </div>
        </div>
    </div>
</div>

