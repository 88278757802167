import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../services/storage.service';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from '../../services/site/login.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(private session: StorageService, private readonly api: LoginService,
              private router: Router, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  logout(): void{
    this.api.logout().subscribe((res) => {
      this.session.clean();
      this.activeModal.dismiss();
      this.router.navigate(['']);
    });
  }

}
