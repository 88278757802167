import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../services/storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css']
})
export class GeneralComponent implements OnInit {
  addClass: string | undefined;
  buttonShow: boolean | undefined;
  ToolbarShow: boolean | undefined;
  footerClass: string | undefined;
  developerPage: boolean | undefined;
  hideFooter: boolean | undefined;
  shopPages: boolean | undefined;
  year = new Date().getFullYear();
  constructor(private storageService: StorageService, private router: Router) { }

  ngOnInit(): void {
    if (!this.storageService.isLoggedIn()) {
      this.router.navigate(['/login']);
    }
  }

  onActivate(componentReference: any): void {
    this.addClass = componentReference.navClass;
    this.buttonShow = componentReference.buttonList;
    this.ToolbarShow = componentReference.sliderTopbar;
    this.footerClass = componentReference.footerVariant;
    this.developerPage = componentReference.isdeveloper;
    this.hideFooter = componentReference.hideFooter;
    this.shopPages = componentReference.shopPages;
  }
}
