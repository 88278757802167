import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LightboxModule } from 'ngx-lightbox';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CountToModule } from 'angular-count-to';
import { NgxMasonryModule } from 'ngx-masonry';

import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MasterPageComponent } from './shared/master-page/master-page.component';
import { HomeComponent } from './home/home-comp/home.component';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { SwitcherComponent } from './shared/switcher/switcher.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { PagePrivacyComponent } from './shared/page-privacy/page-privacy.component';
import { PageTermsComponent } from './shared/page-terms/page-terms.component';
import { HttpClientModule } from '@angular/common/http';
import { StatusPageComponent } from './home/status-page/status-page.component';
import { ModalComponent } from './modal/contact-modal/modal.component';
import { OrderFormularComponent } from './home/order-formular/order-formular.component';
import { LoginComponent } from './auth/login/login.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { UsersComponent } from './admin/users/users.component';
import { GeneralComponent } from './shared/general/general.component';
import { HeaderProfileComponent } from './shared/header-profile/header-profile.component';
import { MenuProfileComponent } from './shared/menu-profile/menu-profile.component';
import { OrdersComponent } from './admin/orders/orders.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { FooterProfileComponent } from './shared/footer-profile/footer-profile.component';
import { SignupComponent } from './auth/signup/signup.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { SetNewPasswordComponent } from './auth/set-new-password/set-new-password.component';
import { SettingsComponent } from './admin/settings/settings.component';
import { DeleteComponentModalComponent } from './modal/delete-component-modal/delete-component-modal.component';
import { AddUserComponent } from './modal/add-user/add-user.component';
import { PackagesModelComponent } from './admin/orders/packages-model/packages-model.component';
import { TemplateNewsletterComponent } from './templates/template-newsletter/template-newsletter.component';
import { OrderFormularInternComponent } from './home/order-formular-intern/order-formular-intern.component';


const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MasterPageComponent,
    HomeComponent,
    SwitcherComponent,
    PagePrivacyComponent,
    PageTermsComponent,
    StatusPageComponent,
    ModalComponent,
    OrderFormularComponent,
    LoginComponent,
    DashboardComponent,
    UsersComponent,
    GeneralComponent,
    HeaderProfileComponent,
    MenuProfileComponent,
    OrdersComponent,
    PageNotFoundComponent,
    FooterProfileComponent,
    SignupComponent,
    LogoutComponent,
    ForgotPasswordComponent,
    SetNewPasswordComponent,
    SettingsComponent,
    DeleteComponentModalComponent,
    AddUserComponent,
    PackagesModelComponent,
    TemplateNewsletterComponent,
    OrderFormularInternComponent
  ],
  imports: [
    TranslateModule.forRoot(),
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    CarouselModule,
    FeatherModule.pick(allIcons),
    RouterModule.forRoot([], {relativeLinkResolution: 'legacy'}),
    NgxYoutubePlayerModule,
    NgbDropdownModule,
    CKEditorModule,
    NgbModule,
    NgbNavModule,
    FormsModule,
    SwiperModule,
    NgxTypedJsModule,
    FlatpickrModule.forRoot(),
    CountToModule,
    NgxMasonryModule,
    LightboxModule,
    SharedModule,
  ],
  exports: [
    FeatherModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    FormBuilder,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
