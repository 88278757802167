import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({providedIn: 'root'})

export class ContactService {
    readonly ROOT_URL = environment.baseUrl + '/api';

    constructor(private readonly http: HttpClient) {
    }

    addToNewsletter(email: string): Observable<any> {
        return this.http.post(this.ROOT_URL + '/subscribeNewsletter',
            {emailAddress: email}, {responseType: 'text'});
    }

    receiveContactEmail(name: string, email: string, mobile: string, subject: string, message: string): Observable<any>{
        return this.http.post(this.ROOT_URL + '/contact', {name, email, mobile, subject, message },
            {responseType: 'text'});
    }
}
