<div class="p-4" style="overflow-x:auto;">
    <div class="table-responsive bg-white shadow rounded">
    <table class="table mb-0 table-center">
        <thead>
            <tr>
                <th scope="col" class="border-bottom">No.</th>
                <th scope="col" class="border-bottom">{{'ORDERS.ACTIUNE' | translate}}</th>
                <th scope="col" class="border-bottom">AWB</th>
                <th scope="col" class="border-bottom">{{'ORDERS.DATE' | translate}}</th>
                <th scope="col" class="border-bottom">{{'ORDERS.INFO' | translate}}</th>
                <th scope="col" class="border-bottom">{{'ORDERS.TOTALW' | translate}}</th>
                <th scope="col" class="border-bottom">{{'ORDERS.TOTALV' | translate}}</th>
                <th scope="col" class="border-bottom">{{'ORDERS.PRICE' | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of orderData">
                <th scope="row">{{data.id}}</th>
                <td>
                    <button (click)="open(content2); orderDetails(data.id)" class="btn btn-icon btn-soft-dark">
                        <i-feather class="mdi mdi-file-document-edit-outline mb-2"></i-feather>
                    </button>
                    <button (click)="open(content)" class="btn btn-icon btn-soft-dark">
                        <i-feather class="mdi mdi-delete-outline mb-2"></i-feather>
                    </button>
                    <ng-template #content let-modal>
                        <div class="modal-header">
                            <div class="p-4"><h5 class="title mb-0"> {{'ORDERS.DELETE' | translate}} </h5></div>
                            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
                        </div>
                        <div class="modal-body">
                            <h4 class="title mb-0 text-center"> {{'ORDERS.QUESTION' | translate}} </h4>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" name="send"
                                    (click)="deleteOrder(data.id);"
                                    class="btn btn-secondary"> {{'ORDERS.YES' | translate}} </button>
                            <button type="submit" class="btn btn-primary" (click)="modal.dismiss('Cross click')"> {{'ORDERS.NO' | translate}} </button>
                        </div>
                    </ng-template>

                    <ng-template #content2 let-modal>
                <div class="cls justify-content-center">
                    <div class="content">
                        <div class="modal-header">
                            <div><h4 class="title mb-0"> {{'ORDERS.EDITO' | translate}} </h4></div>
                            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
                        </div>

                    <div class="modal-body">
                        <form [formGroup]="editOrderForm" (ngSubmit)="editOrder(data.id); orderDetails(data.id)">
                            <div class="p-4">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <ul ngbNav #nav="ngbNav" class="nav-pills nav-justified">
                                            <li ngbNavItem>
                                                <a ngbNavLink class=" rounded">
                                                    <div class="text-center pt-1 pb-1">
                                                        <h6 class="title fw-normal mb-0">{{'ORDERS.SENDER' | translate}}</h6>
                                                    </div>
                                                </a>

                                                <ng-template ngbNavContent>
                                                    <div class="row">
                                                        <div class="col-lg-6">
                                                            <div class="mb-1">
                                                                <label class="form-label"> {{'ORDERS.COUNTY.SIMPLE' | translate}} <span class="text-danger">*</span></label>
                                                                <select class="form-select form-control" formControlName="collectingCounty"
                                                                         (click)="getCollectingLocality()">
                                                                    <option *ngFor="let county of countiesCollecting"> {{county}} </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <!--end col-->
                                                        <div class="col-lg-6">
                                                            <div class="mb-1">
                                                                <label class="form-label"> {{'ORDERS.LOCALITY.SIMPLE' | translate}} <span class="text-danger">*</span></label>
                                                                <div class="form-icon position-relative">
                                                                        <select class="form-select form-control" formControlName="collectingLocality">
                                                                            <option  value="" hidden (reset)="!collectingCounty"> </option>
                                                                            <option value="{{locality.locality}}"
                                                                                    *ngFor="let locality of localitiesCollecting"> {{locality.locality}} ({{locality.km}} km)
                                                                            </option>
                                                                        </select>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <div class="mb-1">
                                                                <label class="form-label"> {{'ORDERS.ADDRESS.SIMPLE' | translate}} <span class="text-danger">*</span></label>
                                                                <div class="form-icon position-relative">
                                                                    <input formControlName="collectingAdrress" name="plate"  type="text"
                                                                           class="form-control" placeholder="{{'ORDERS.ADDRESS.COLLECTINGADDRESS' | translate}}">
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <div class="mb-1">
                                                                <label class="form-label"> {{'ORDERS.NAME.SIMPLE' | translate}} <span class="text-danger">*</span></label>
                                                                <div class="form position-relative">
                                                                    <input formControlName="expeditorName" name="url" type="text"  class="form-control"
                                                                           placeholder="{{'ORDERS.NAME.COLLECTINGNAME' | translate}}">
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <div class="mb-1">
                                                                <label class="form-label"> {{'ORDERS.PHONE.SIMPLE' | translate}} <span class="text-danger">*</span></label>
                                                                <div class="form position-relative">
                                                                    <input formControlName="expeditorPhone" name="url" type="text" class="form-control"
                                                                           placeholder="{{'ORDERS.PHONE.COLLECTINGPHONE' | translate}}">
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <div class="mb-1">
                                                                <label class="form-label"> Email <span class="text-danger">*</span></label>
                                                                <div class="form position-relative">
                                                                    <input formControlName="expeditorEmail" name="url" type="text" class="form-control"
                                                                           placeholder="Expeditor Email">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </li>

                                            <li ngbNavItem>
                                                <a ngbNavLink class=" rounded">
                                                    <div class="text-center pt-1 pb-1">
                                                        <h6 class="title fw-normal mb-0">{{'ORDERS.RECIPIENT' | translate}}</h6>
                                                    </div>
                                                </a>
                                                <ng-template ngbNavContent>
                                                    <div class="row">
                                                    <div class="col-lg-6">
                                                        <div class="mb-1">
                                                            <label class="form-label"> {{'ORDERS.COUNTY.SIMPLE' | translate}} <span class="text-danger">*</span></label>
                                                           <select class="form-select form-control" formControlName="deliveryCounty"
                                                                    [(ngModel)]="deliveryCounty" (click)="getDestinationLocality()">
                                                                <option *ngFor="let county of countiesDestination"> {{county}} </option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-6">
                                                        <div class="mb-1">
                                                            <label class="form-label"> {{'ORDERS.LOCALITY.SIMPLE' | translate}} <span class="text-danger">*</span></label>
                                                                 <select class="form-select form-control" formControlName="deliveryLocality">
                                                                    <option value="{{locality.locality}}"
                                                                            *ngFor="let locality of localitiesDestination"> {{locality.locality}} ({{locality.km}} km)
                                                                    </option>
                                                                </select>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-6">
                                                        <div class="mb-1">
                                                            <label class="form-label"> {{'ORDERS.ADDRESS.SIMPLE' | translate}} <span class="text-danger">*</span></label>
                                                            <div class="form position-relative">
                                                                <input formControlName="deliveryAdrress" name="url" type="text" class="form-control"
                                                                       placeholder="{{'ORDERS.ADDRESS.DELIVERYADDRESS' | translate}}">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-6">
                                                        <div class="mb-1">
                                                            <label class="form-label"> {{'ORDERS.NAME.SIMPLE' | translate}} <span class="text-danger">*</span></label>
                                                            <div class="form-icon position-relative">
                                                                <input formControlName="destinatarName" name="number" type="text" class="form-control"
                                                                       placeholder="{{'ORDERS.NAME.DELIVERYNAME' | translate}}">
                                                            </div>
                                                        </div>
                                                    </div>
                                        <!--end col-->
                                                    <div class="col-lg-6">
                                                        <div class="mb-1">
                                                            <label class="form-label"> {{'ORDERS.PHONE.SIMPLE' | translate}} <span class="text-danger">*</span></label>
                                                            <div class="form-icon position-relative">
                                                                <input formControlName="destinatarPhone" name="plate" type="text" class="form-control"
                                                                       placeholder="{{'ORDERS.NAME.DELIVERYPHONE' | translate}}">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </ng-template>
                                            </li>

                                            <li ngbNavItem>
                                                <a ngbNavLink class=" rounded">
                                                    <div class="text-center pt-1 pb-1">
                                                        <h6 class="title fw-normal mb-0"> {{'ORDERS.ORDER' | translate}} </h6>
                                                    </div>
                                                </a>
                                                <ng-template ngbNavContent>
                                                    <div class="row">
                                                    <div class="col-lg-6">
                                                        <div class="mb-1">
                                                            <label class="form-label"> {{'ORDERS.REPAYMENTV' | translate}} </label>
                                                            <div class="form-icon position-relative">
                                                                <input formControlName="repaymentValue" name="plate" type="text"
                                                                       class="form-control" placeholder="{{'ORDERS.REPAYMENTV' | translate}}">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-6">
                                                        <div class="mb-1">
                                                            <label class="form-label"> {{'ORDERS.REPAYMENTT' | translate}} </label>
                                                            <select class="form-select form-control" formControlName="repaymentType">
                                                                <option value="">{{'FORMULAR.ALEGE' | translate}}</option>
                                                                <option value="cash">Cash</option>
                                                                <option value="cont">{{'FORMULAR.CONT' | translate}}</option>
                                                                <option value="bilet">{{'FORMULAR.BILET' | translate}}</option>
                                                                <option value="fila">{{'FORMULAR.FILACEC' | translate}}</option>
                                                                <option value="factura">{{'FORMULAR.FACTURA' | translate}}</option>
                                                                <option value="altedoc">{{'FORMULAR.ALTEDOC' | translate}}</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-6">
                                                        <div class="mb-1">
                                                            <label class="form-label"> {{'ORDERS.INSURANCEP' | translate}} </label>
                                                            <div class="form position-relative">
                                                                <input formControlName="insurancePackage" name="url" type="text" class="form-control"
                                                                       placeholder="{{'ORDERS.INSURANCEP' | translate}}">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="mb-1 mt-4">
                                                            <div class="form-check">
                                                                <input type="checkbox" class="form-check-input" formControlName="verifyPackage">
                                                                <label class="form-check-label"> {{'ORDERS.VERIFYP' | translate}} </label>
                                                            </div>
<!--                                                            <div class="form-check">-->
<!--                                                                <input type="checkbox" class="form-check-input" [(ngModel)]="saturdayDelivery" value="{{saturdayDelivery}}"-->
<!--                                                                       [ngModelOptions]="{standalone: true}">-->
<!--                                                                <label class="form-check-label"> {{'ORDERS.SATURDAY' | translate}} </label>-->
<!--                                                            </div>-->
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="mb-1">
                                                            <label class="form-label"> {{'ORDERS.COMMENTS' | translate}} </label>
                                                            <div class="form position-relative">
                                                                <textarea formControlName="comments" name="url" type="text" class="form-control"
                                                                          placeholder="{{'ORDERS.COMMENTS' | translate}}"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-6">
                                                        <label class="form-label"> {{'ORDERS.OBLIGATORIU' | translate}} <span class="text-danger">*</span></label>
                                                        <div class="d-flex justify-content-between">
                                                            <form>
                                                                <input type="radio" name="plata" [(ngModel)]="plataType" value="expeditor"> {{'ORDERS.PAYE' | translate}} <br>
                                                                <input type="radio" name="plata" [(ngModel)]="plataType" value="destinatar"> {{'ORDERS.PAYD' | translate}}
                                                            </form>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </ng-template>
                                            </li>

                                            <li ngbNavItem>
                                                <a ngbNavLink class=" rounded">
                                                    <div class="text-center pt-1 pb-1">
                                                        <h6 class="title fw-normal mb-0"> {{'ORDERS.PACKAGE' | translate}} </h6>
                                                    </div>
                                                </a>
                                                <ng-template ngbNavContent>
                                                    <app-packages-model [packageData]="packages"></app-packages-model>
                                                </ng-template>
                                            </li>
                                        </ul>
                                        <div [ngbNavOutlet]="nav" class="pt-2"></div>
                                    </div>
                                    <!--end col-->
                                </div>
                                <!--end row-->
                            </div>

                            <div class="modal-footer">
                                <div class="col-lg-12">
                                    <button type="submit" id="submitsubscribe" name="send"
                                    [disabled]="editOrderForm.invalid"
                                    class="btn btn-primary"> {{'ORDERS.EDIT' | translate}} </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    </div>
                </div>
            </ng-template>
                </td>
                <td>{{data.awbCode}}</td>
                <td>{{data.created}}</td>
                <td>{{data.orderDetail}}</td>
                <td>{{data.totalWeight}}</td>
                <td>{{data.totalVolume}}</td>
                <td>{{data.cost}}</td>
            </tr>
        </tbody>
    </table>
    </div>
</div>
