import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

const ROOT_URL = environment.baseUrl + '/api/auth/';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'}),
    withCredentials: true
};

@Injectable({providedIn: 'root'})
export class LoginService {

    constructor(private http: HttpClient) {}

    login(username: string | null, password: string | null): Observable<any> {
        return this.http.post(
            ROOT_URL + 'login',
            {
                username,
                password,
            },
            httpOptions
        );
    }

    signup(username: string, password: string, firstName: string,
           lastName: string, expired: boolean, locked: boolean,
           enabled: boolean, role: string): Observable<any> {
        return this.http.post(
            ROOT_URL + 'signup',
            {
                username,
                password,
                firstName,
                lastName,
                expired,
                locked,
                enabled,
                role,
            },
            httpOptions
        );
    }

    logout(): Observable<any> {
        return this.http.post(ROOT_URL + 'logout', { }, httpOptions);
    }


    forgotPassword(email: string, path: string): Observable<any> {
        return this.http.post(
            ROOT_URL + 'forgotPassword',
            {
                email,
                path
            },
            httpOptions
        );
    }

    updatePassword(recoveryId: string, password: string): Observable<any>{
        return this.http.post(
            ROOT_URL + 'updatePassword',
            {
                recoveryId,
                password
            },
            httpOptions
        );
    }
}
