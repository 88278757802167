<div class="border-bottom text-center py-3 d-flex justify-content-end" style="padding-bottom: 13px;">
    <a class="btn btn-primary" (click)="addUser()"> +User </a>
</div>
<div class="card border-0 rounded shadow">
    <table class="table table-center mb-0">
        <thead >
        <tr>
            <th scope="col" class="border-bottom py-3"> ID </th>
            <th scope="col" class="border-bottom py-3"> {{'USERS.ACTION' | translate}} </th>
            <th scope="col" class="border-bottom py-3"> {{'USERS.PHONE' | translate}} </th>
            <th scope="col" class="border-bottom py-3"> EMAIL </th>
            <th scope="col" class="border-bottom py-3"> STATUS </th>
            <th scope="col" class="border-bottom py-3"> {{'USERS.COUNTIES' | translate}} </th>
            <th scope="col" class="border-bottom py-3"> {{'USERS.ROLE' | translate}} </th>
            <th scope="col" class="border-bottom py-3"> {{'USERS.FNAME' | translate}} </th>
            <th scope="col" class="border-bottom py-3"> {{'USERS.LNAME' | translate}} </th>
        </tr>
        </thead>

        <tbody>
        <tr class="shop-list" *ngFor="let data of userData">
            <td>
                <div class="d-flex align-items-center"> {{data.id}}</div>
            </td>
            <td>
                <button (click)="open(content2); userDetails(data.id)" class="btn btn-icon btn-soft-dark">
                    <i-feather class="mb-2 mdi mdi-file-document-edit-outline"></i-feather>
                </button>
                <button (click)="open(content)" class="btn btn-icon btn-soft-dark">
                    <i-feather class="mb-2 mdi mdi-delete-outline"></i-feather>
                </button>

                <ng-template #content let-modal>
                    <div class="modal-header">
                        <div class="p-4"><h5 class="title mb-0"> {{'USERS.DELETE.SIMPLE' | translate}} </h5></div>
                        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
                    </div>
                    <div class="modal-body">
                        <h4 class="title mb-0 text-center"> {{'USERS.DELETE.QUESTION' | translate}} </h4>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" id="" name="send"
                                (click)="deleteUser(data.id);"
                                class="btn btn-secondary"> {{'USERS.DELETE.YES' | translate}} </button>
                        <button type="submit" class="btn btn-primary" (click)="modal.dismiss('Cross click')"> {{'USERS.DELETE.NO' | translate}} </button>
                    </div>
                </ng-template>

                <ng-template #content2 let-modal>
                    <div class="content">
                        <div class="modal-header">
                            <div><h4 class="title mb-0"> {{'USERS.EDIT.SIMPLE' | translate}} </h4></div>
                            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
                        </div>

                        <div class="modal-body">
                            <form [formGroup]="editUserForm" (ngSubmit)="editUser(data.id); userDetails(data.id)">
                                <div class="row">
                                    <div class="row ">
                                        <div class="col-lg-12">
                                            <div class="mb-1">
                                                <label class="form-label"> Username <span class="text-danger">*</span></label>
                                                <div class="form-icon position-relative">
                                                    <input formControlName="username" name="number" type="text" class="form-control"
                                                           placeholder="Username" disabled>
                                                </div>
                                            </div>
                                        </div>
                                        <!--end col-->
                                        <div class="col-lg-12">
                                            <div class="mb-1">
                                                <label class="form-label"> {{'USERS.FNAME' | translate}} <span class="text-danger">*</span></label>
                                                <div class="form-icon position-relative">
                                                    <input formControlName="firstName" name="plate" type="text" class="form-control"
                                                           placeholder="{{'USERS.FNAME' | translate}}">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="mb-1">
                                                <label class="form-label"> {{'USERS.LNAME' | translate}} <span class="text-danger">*</span></label>
                                                <div class="form-icon position-relative">
                                                    <input formControlName="lastName" type="text"
                                                           class="form-control" placeholder="{{'USERS.LNAME' | translate}}" >
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="mb-1">
                                                <label class="form-label"> {{'USERS.PHONE' | translate}} <span class="text-danger">*</span></label>
                                                <div class="form position-relative">
                                                    <input formControlName="phone" name="url" type="text"  class="form-control"
                                                           placeholder="{{'USERS.PHONE' | translate}}" >
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="mb-1">
                                                <label class="form-label"> {{'USERS.COUNTIES' | translate}} <span class="text-danger">*</span></label>
                                                <div class="form position-relative">
                                                    <input formControlName="counties" name="url" type="text"  class="form-control"
                                                           placeholder="{{'USERS.COUNTIES' | translate}}" >
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="mb-1">
                                                <label class="form-label"> Status <span class="text-danger">*</span></label>
                                                <select  class="form-select form-control" formControlName="status">
                                                    <option value=""> -Select- </option>
                                                    <option value="true"> Active </option>
                                                    <option value="false"> Inactive </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="modal-footer">
                                    <div class="col-lg-12">
                                        <button type="submit" id="submitsubscribe" name="send"
                                                [disabled]="editUserForm.invalid"
                                                class="btn btn-primary"> {{'USERS.EDIT.BUTTON' | translate}}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-template>

            </td>
            <td>
                <div class="d-flex align-items-center"> {{data.phone}}</div>
            </td>
            <td>
                <div class="d-flex align-items-center"> {{data.username}}</div>
            </td>
            <td>
                <div class=" badge bg-success text-center" *ngIf="data.enabled"> Active </div>
            </td>
            <td>
                <div class="d-flex align-items-center"> {{data.counties}}</div>
            </td>
            <td>
                <div class="d-flex align-items-center"> {{data.role}}</div>
            </td>
            <td>
                <div class="d-flex align-items-center"> {{data.firstName}} </div>
            </td>
            <td>
                <div class="d-flex align-items-center"> {{data.lastName}}</div>
            </td>
        </tr>
        </tbody>
    </table>
</div>
