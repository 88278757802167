import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, Validators } from '@angular/forms';
import { ModalComponent } from '../../modal/contact-modal/modal.component';
import { ContactService } from '../../services/site/contact.service';
import { debounce } from 'lodash';
import {ConstantsService} from "../../services/constants.service";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})

/***
 * Footer Component
 */
export class FooterComponent implements OnInit {
    telefon = '';
    siteEmail = '';
    siteName = '';
    @Input() footerVariant: string;
    @Input() hideFooter: boolean;

    year = new Date().getFullYear();
    isSubscribe: boolean;
    errorMessage: boolean;
    email: FormControl = new FormControl('', Validators.compose([
        Validators.required,
        Validators.email,
    ]));

    constructor(private modalService: NgbModal, private readonly api: ContactService) {
        this.onSubmit = debounce(this.onSubmit, 600);
    }


    onSubmit(): void {
        this.api.addToNewsletter(this.email.value).subscribe({
            next: data => {
                this.isSubscribe = true;
            },
            error: err => {
                this.errorMessage = true;
            }
        });
        this.email.reset();
    }

    ngOnInit(): void {
        this.telefon = ConstantsService.telefon;
        this.siteEmail = ConstantsService.email;
        this.siteName = ConstantsService.siteName;
    }

    open(): void {
        const modalRef = this.modalService.open(ModalComponent);
        modalRef.componentInstance.name = 'World';
    }
    mapView(content): void {
        this.modalService.open(content, {windowClass: 'dark-modal', size: 'lg', centered: true});
    }

    Subscribeopen(content): void {
        this.modalService.open(content, { size: 'lg', windowClass: 'modal-holder', centered: true });
    }

    scroll(elementId: string): void {
        document.getElementById(elementId).scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
        });
        window.history.pushState('page2', 'Title', '#' + elementId);
    }

    scrollTop(): void {
        document.getElementById('home').scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
        });
    }
}
