import { Component, OnInit } from '@angular/core';
import {OrderService} from '../../services/site/order.service';
import {UserService} from '../../services/admin/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-order-formular-intern',
  templateUrl: './order-formular-intern.component.html',
  styleUrls: ['./order-formular-intern.component.css']
})
export class OrderFormularInternComponent implements OnInit {

  constructor(private readonly orderService: OrderService, private readonly userService: UserService,
              private route: ActivatedRoute, private router: Router, private modalService: NgbModal) { }
  res: string;
  statusMessage: boolean;
  vcost: any;
  vcostType: any;
  details: any;
  verifyPackage: boolean;
  saturdayDeliv: boolean;
  orderDetailsForm = new FormGroup({
    collecting_country: new FormControl('', Validators.required),
    collecting_address: new FormControl('', Validators.required),
    collecting_name: new FormControl('', Validators.required),
    collecting_phone: new FormControl('', Validators.required),
    collecting_email: new FormControl('', Validators.compose([
      Validators.required,
      Validators.email
    ])),
    delivery_country: new FormControl('', Validators.required),
    delivery_address: new FormControl('', Validators.required),
    delivery_name: new FormControl('', Validators.required),
    delivery_phone: new FormControl('', Validators.required),
    ramburs_value: new FormControl(''),
    ramburs_type: new FormControl(''),
    insurance_package: new FormControl(''),
    comments: new FormControl(''),
    paymentType: new FormControl('', Validators.required),
    terms: new FormControl('', Validators.requiredTrue),
  });

  ngOnInit(): void {
    this.route.data.subscribe(v => console.log(v));
    const { cost, costType, cCountry, dCountry, detailsPack } = window.history.state;
    this.vcost = cost;
    this.vcostType = costType;
    this.details = detailsPack ? JSON.parse(detailsPack) : undefined;
    this.orderDetailsForm.patchValue({
      collecting_country: cCountry,
      delivery_country: dCountry
    });
  }

  onSubmit(content: any): void {
    this.orderService.addOrderDetails(this.orderDetailsForm.controls.collecting_country.value, '',
        '', this.orderDetailsForm.controls.collecting_address.value,
        this.orderDetailsForm.controls.collecting_name.value,  '',
        this.orderDetailsForm.controls.collecting_phone.value, this.orderDetailsForm.controls.collecting_email.value,
        this.orderDetailsForm.controls.delivery_country.value, '', '',
        this.orderDetailsForm.controls.delivery_address.value, this.orderDetailsForm.controls.delivery_name.value,
        '', this.orderDetailsForm.controls.delivery_phone.value,
        '', this.orderDetailsForm.controls.ramburs_value.value, this.orderDetailsForm.controls.ramburs_type.value,
        '',
        this.orderDetailsForm.controls.insurance_package.value, this.verifyPackage,
        this.saturdayDeliv, this.orderDetailsForm.controls.paymentType.value, this.vcostType,
        this.orderDetailsForm.controls.comments.value, this.details
    ).subscribe( {
      next: data => {
        this.statusMessage = true;
        this.modalService.open(content, { size: 'lg', windowClass: 'modal-holder', centered: true });
        setTimeout(() => {
          this.router.navigate(['/']);
          this.modalService.dismissAll();
        }, 3000);
      },
      error: err => {
        this.statusMessage = false;
        this.modalService.open(content, { size: 'lg', windowClass: 'modal-holder', centered: true });
        setTimeout(() => {
          this.router.navigate(['/']);
          this.modalService.dismissAll();
        }, 3000);
      }
    });
  }

  costCalc(): void{
    const cost = {
      collecting_country: this.orderDetailsForm.controls.collecting_country.value,
      delivery_country: this.orderDetailsForm.controls.delivery_country.value,
      insurancePackage: this.orderDetailsForm.controls.insurance_package.value,
      withCurrency: true,
      userId: 1,
      packages: this.details,
      packageType: this.vcostType
    };
    this.orderService.postInternationalCost(cost).subscribe((res) => {
      this.vcost = res[this.vcostType] === undefined ? 'Indisponibil' : res[this.vcostType];
    });
  }

}
