import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { OrderStatusService } from '../../services/site/order.status.service';

@Component({
    selector: 'app-status-page',
    templateUrl: './status-page.component.html',
    styleUrls: ['./status-page.component.css']
})
export class StatusPageComponent implements OnInit {
    awb = new FormControl('');
    progressPercent: number;
    dateId1: Date;
    dateId2: Date;
    dateId3: Date;
    dateId4: Date;
    dateId5: Date;
    dateId6: Date;
    dateR: Date;
    dateRF: Date;
    message: string;
    width: number;

    constructor(private readonly api: OrderStatusService) {
    }

    ngOnInit(): void {
    }

    // @ts-ignore
    getLastStatus(): number {
        this.api.getLastStatus(this.awb.value).subscribe((res) => {
            const status = res.status;
            const date = res.date_created;
            this.width = 20;
            if (status === 'CONFIRMED') {
                    this.progressPercent = 1;
                } else if (status === 'TAKEN') {
                    this.progressPercent = 2;
                } else if (status === 'STORED') {
                    this.progressPercent = 3;
                } else if (status === 'ON THE WAY') {
                    this.progressPercent = 4;
                } else if (status === 'POSTPONE') {
                    this.progressPercent = 5;
                    this.width = 16;
                } else if (status === 'DELIVERED') {
                    this.progressPercent = 6;
                }  else if (status === 'RETURN') {
                    this.progressPercent = 7;
                } else if (status === 'REFUND') {
                    this.progressPercent = 8;
                } else if (status === 'CANCEL') {
                    this.progressPercent = 9;
                }
        });
    }

    getDateForStatus(): void {
        this.api.findDate(this.awb.value).subscribe((res) => {
                this.dateId1 = res.CONFIRMED === undefined ? '<br>' : res.CONFIRMED;
                this.dateId2 = res.TAKEN === undefined ? '<br>' : res.TAKEN;
                this.dateId3 = res.STORED === undefined ? '<br>' : res.STORED;
                this.dateId4 = res['ON THE WAY'] === undefined ? '<br>' : res['ON THE WAY'];
                this.dateId5 = res.POSTPONE === undefined ? '<br>' : res.POSTPONE;
                this.dateId6 = res.DELIVERED === undefined ? '<br>' : res.DELIVERED;
                this.dateR = res.RETURN === undefined ? '<br>' : res.RETURN;
                this.dateRF = res.REFUND === undefined ? '<br>' : res.REFUND;
        });
    }

}
