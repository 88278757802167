<section>
    <div class="content">
        <div class="modal-header">
            <div class="p-4"><h5 class="title mb-0 text-primary"> {{'LOGOUT.LOGOUT' | translate}} </h5></div>
            <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <h4 class="title mb-0 text-center"> {{'LOGOUT.QUESTION' | translate}} </h4>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-secondary" (click)="logout()">{{'LOGOUT.YES' | translate}}</button>
            <button type="submit" class="btn btn-primary" (click)="activeModal.dismiss('Cross click')">{{'LOGOUT.NO' | translate}}</button>
        </div>
    </div>
</section>
