<!-- Navbar STart -->

<header id="topnavedit" class="defaultscroll sticky" (window:scroll)="windowScroll()" [class]="navClass"
  [ngClass]="{'tagline-height': sliderTopbar == true}" >

  <div class="container" id="nav">
    <div id="navigation" class="justify-content-center">
      <ul class="navigation-menu " [class]="navClass">
        <div class="d-flex flex-row-reverse" >
            <li class="p-2 d-flex contact-detail align-items-center mt-3">
                <button class="btn btn-primary" (click)="open()">Contact</button>
            </li>
            <li class="p-2 d-flex contact-detail align-items-center mt-3">
                <a>
                    <img style="height: 20px; cursor: pointer"
                         src="assets/images/united-kingdom2.svg" class="avatar avatar-small" alt=""
                         (click)="useLanguage('en')">
                </a>
                <a>
                    <img style="height: 20px; cursor: pointer; margin-left: -24px; margin-right: -15px;"
                         src="assets/images/romania2.svg" class="avatar avatar-small"
                         (click)="useLanguage('ro')" alt="">
                </a>
            </li>
          <li class="p-2 d-flex contact-detail align-items-center mt-3">
            <i-feather name="user" class="fea icon-m-md text-dark mb-2"></i-feather>
            <a routerLink="/login" class="text-black-50"> Cont </a>
          </li>
          <li class="p-2 d-flex contact-detail align-items-center mt-3">
            <i-feather name="mail" class="fea icon-m-md text-dark"></i-feather>
            <a href="mailto:{{siteEmail}}" class="text-black-50"> &nbsp;{{siteEmail}}</a>
          </li>
          <li class="p-2 d-flex contact-detail align-items-center mt-3">
            <i-feather name="phone" class="fea icon-m-md text-dark"></i-feather>
            <a href="tel:{{telefon}}" class="text-black-50"> &nbsp;{{telefon}}</a>
          </li>
          <a class="logo" routerLink="" *ngIf="navClass !== 'nav-light'" style="margin-top: -14px;">
            <img (click)="scroll('home')" style="margin-top: 30px;" src="assets/images/logo.png" height="50" alt="">
          </a>
        </div>
      </ul>
      <!--end navigation menu-->
      <div class="d-none">
        <a href="" class="btn btn-primary"> {{'HEADER.BUTTON' | translate}}</a>
      </div>

    </div>

    <div class="desktop">
      <div class="col" style="display: block;">
        <nav class="navbar navbar-dark">
          <div class="container-fluid">
            <a>
              <img style="height: 20px; cursor: pointer"
                   src="assets/images/romania2.svg" class="avatar avatar-small"
                   (click)="useLanguage('ro')" alt="">

              <img style="height: 20px; cursor: pointer"
                   src="../../../assets/images/united-kingdom2.svg" class="avatar avatar-small"
                   (click)="useLanguage('en')" alt="">
              <button class="btn btn-primary" (click)="open()">Contact</button>
            </a>

            <button class="navbar-toggler btn-primary" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarsExample01" aria-controls="" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="navbar-collapse collapse" id="navbarsExample01">
              <ul class="navbar-nav me-auto mb-2">
                <li class="p-2 d-flex contact-detail align-items-center mt-3">
                  <i-feather name="home" class="fea icon-m-md text-dark me-3"></i-feather>
                  <a routerLink="/" class="text-black-50"> Home </a>
                </li>
                <li class="p-2 d-flex contact-detail align-items-center mt-3">
                  <i-feather name="user" class="fea icon-m-md text-dark mb-2"></i-feather>
                  <a routerLink="/login" class="text-black-50"> Cont </a>
                </li>
                <li class="p-2 d-flex contact-detail align-items-center mt-3">
                  <i-feather name="mail" class="fea icon-m-md text-dark"></i-feather>
                  <a href="mailto:{{siteEmail}}" class="text-black-50"> &nbsp;{{siteEmail}}</a>
                </li>
                <li class="p-2 d-flex contact-detail align-items-center mt-3">
                  <i-feather name="phone" class="fea icon-m-md text-dark"></i-feather>
                  <a href="tel:{{telefon}}" class="text-black-50"> &nbsp;{{telefon}}</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</header>


