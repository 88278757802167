<section class="bg-home bg-circle-gradiant d-flex align-items-center">
    <div class="bg-overlay bg-overlay-white"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-5 col-md-8">
                    <div class="card-body">
                        <h4 class="card-title text-center">{{'RESETPASSWORD.RESET' | translate}}</h4>
                        <form class="login-form mt-4" name="form" [formGroup]="form" (ngSubmit)="onSubmit()" >
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="mb-3 form-group">
                                        <label class="form-label" for="password"> {{'RESETPASSWORD.NEWPASSWORD' | translate}} <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="key" class="fea icon-sm icons"></i-feather>
                                            <input placeholder="{{ 'RESETPASSWORD.NEWPASSWORD' | translate }}"
                                                   class="form-control ps-5"
                                                   formControlName="password"
                                                   id="password"
                                                   type="password">
                                            <div *ngIf="form.controls['password'].invalid && (form.controls['password'].dirty || form.controls['password'].touched)" class="alert">
                                                <div class="alert alert-danger" *ngIf="form.controls['password'].errors['required']">
                                                    {{'RESETPASSWORD.REQUIRED' | translate}}
                                                </div>
                                                <div class="alert alert-danger" *ngIf="form.controls['password'].errors['minlength']">
                                                    {{'RESETPASSWORD.MINLENGTH' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                

                                <div class="col-lg-12">
                                    <div class="mb-3 form-group">
                                        <label  for="confirm_password" class="form-label">{{'RESETPASSWORD.REPASSWORD' | translate}} <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="key" class="fea icon-sm icons"></i-feather>
                                            <input
                                                    class="form-control ps-5"
                                                    formControlName="confirm_password"
                                                    id="confirm_password"
                                                    type="password"
                                                    placeholder="{{ 'RESETPASSWORD.REPASSWORD' | translate }}">
                                            <div *ngIf="form.controls['confirm_password'].invalid && (form.controls['confirm_password'].dirty || form.controls['confirm_password'].touched)" class="alert">
                                                <div class="alert alert-danger" *ngIf="form.controls['confirm_password'].errors['required']">
                                                    {{'RESETPASSWORD.REQUIRED' | translate}}
                                                </div>
                                                <div class="alert alert-danger" *ngIf="form.controls['confirm_password'].errors['confirmedValidator']">
                                                    {{'RESETPASSWORD.MATCHPASS' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                

                                <div class="col-lg-12 mb-0">
                                    <div class="d-grid">
                                        <button [disabled]="!form.valid" class="btn btn-primary">{{'RESETPASSWORD.RESET' | translate}}</button>
                                    </div>
                                    <div *ngIf="isMessage" class="alert alert-success">
                                        {{ 'RESETPASSWORD.SUCCES' | translate }}
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
            </div>
        </div>
    </div>
</section>


