<div class="sidebar p-4 rounded">
    <a class="" routerLink="/">
        <img src="../../../assets/images/logo.png" class="l-dark logo-light-mode" height="50" alt="">
    </a>

    <div class="widget mt-4">
        <ul class="list-unstyled sidebar-nav mb-0" id="navmenu-nav">
            <li class="navbar-item account-menu px-0" routerLink="/profile/dashboard" routerLinkActive="active" style="cursor: pointer">
                <a class="navbar-link d-flex rounded shadow align-items-center py-2 px-4">
                    <span class="h4 mb-0"><i class="uil uil-dashboard"></i></span>
                    <h6 class="mb-0 ms-2">DASHBOARD</h6>
                </a>
            </li>
            <li *ngIf="isAdminOrManager" class="navbar-item account-menu px-0 mt-2" routerLink="/profile/users" routerLinkActive="active" style="cursor: pointer">
                <a   class="navbar-link d-flex rounded shadow align-items-center py-2 px-4">
                    <span class="h4 mb-0"><i class="uil uil-users-alt"></i></span>
                    <h6 class="mb-0 ms-2">USERS</h6>
                </a>
            </li>
            <li class="navbar-item account-menu px-0 mt-2" routerLink="/profile/orders" routerLinkActive="active" style="cursor: pointer">
                <a class="navbar-link d-flex rounded shadow align-items-center py-2 px-4">
                    <span class="h4 mb-0"><i class="uil uil-clipboard-notes"></i></span>
                    <h6 class="mb-0 ms-2">Orders</h6>
                </a>
            </li>
        </ul>
    </div>
</div>
