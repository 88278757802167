import { Component, OnInit } from '@angular/core';
import {UsersDetails} from '../../model/api-service.interface';
import {UserService} from '../../services/admin/user.service';
import {AddUserComponent} from '../../modal/add-user/add-user.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {StorageService} from '../../services/storage.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})

export class UsersComponent implements OnInit {
  role: string;
  editUserForm: FormGroup = this.fb.group({
    username: ['', [ Validators.required]],
    firstName: [''],
    lastName: [''],
    phone: ['', [ Validators.required]],
    counties: ['', [ Validators.required]],
    status: ['', [ Validators.required]]
  });
  userData: UsersDetails[] = [];
  constructor(private userService: UserService, private modalService: NgbModal,
              private fb: FormBuilder, private route: ActivatedRoute, private storageService: StorageService) { }

  ngOnInit(): void {
    this.userService.getUsers().subscribe((res) => {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < res.length; i++){
        if (res[i].role !== 'MANAGER' && res[i].role !== 'ADMIN' && this.storageService.getUser().role === 'MANAGER'){
          this.userData = this.userData.concat(res[i]);
        }
        else if (this.storageService.getUser().role === 'ADMIN'){
          this.userData = res;
        }
      }
    });
  }

  addUser(): void {
    const modalRef = this.modalService.open(AddUserComponent);
    modalRef.componentInstance.name = '';
  }

  open(content: any): void {
    this.modalService.open(content, { centered: true });
  }

  deleteUser(userId: any): void{
    this.userService.deleteUser(userId).subscribe( {
      next: data => {
          window.location.reload();
      }
    });
  }

  userDetails(userId: number): void{
    this.userService.userDetails(userId).subscribe(res => {
      this.editUserForm.patchValue({
        username: res.username,
        firstName: res.firstName,
        lastName: res.lastName,
        phone: res.phone,
        counties: res.counties,
        status: res.status
      });
      this.role = res.role;
    });
  }

  editUser(userId: number): void{
    const {username, firstName, lastName, phone,
      counties, status, role} = this.editUserForm.controls;
    this.userService.updateUser(userId, username.value, firstName.value, lastName.value, phone.value,
        counties.value,  status.value, '', this.role).subscribe({
      next: data => {
          window.location.reload();
      }
    });
  }
}
