<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title h3"> {{'PRIVACY.TITLE' | translate}}</h4>
          <ul class="list-unstyled mt-4">
            <li class="list-inline-item h6 date text-muted"> <span class="text-dark">{{'PRIVACY.REVISED' | translate}}</span> 7th Jul,
              2022</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>

<section id="content">

  <div class="content-wrap pb-0">

    <div class="container clearfix">

      <div class="row justify-content-center mb-5">
        <div class="col-lg-12 justify">
          <div class="heading-block">
            <p class="mb-2">{{'PRIVACY.P1' | translate}} </p>
            <p class="mb-2">{{'PRIVACY.P2' | translate}}</p>
            <p class="mb-2">{{'PRIVACY.P3' | translate}}</p>
            <p class="mb-2">{{'PRIVACY.P4' | translate}}</p>
            <p class="mb-2">{{'PRIVACY.P5' | translate}}</p>
            <p class="mb-2">{{'PRIVACY.P6' | translate}}</p>
            <p class="mb-2">{{'PRIVACY.P7' | translate}}</p>
            <p class="mb-2">{{'PRIVACY.P8' | translate}}</p>
            <p class="mb-2">{{'PRIVACY.P9' | translate}}</p>
            <p class="mb-2">{{'PRIVACY.P10' | translate}}</p>
            <p class="mb-2">{{'PRIVACY.P11' | translate}}</p>
            <p class="mb-2">{{'PRIVACY.P12' | translate}}</p>
            <p class="mb-2">{{'PRIVACY.P13' | translate}}</p>
            <p class="mb-2">{{'PRIVACY.P14' | translate}}</p>
          </div>
        </div>

      </div>

    </div>

    <div class="clear"></div>

  </div>

</section>

