<section class="bg-half-170 pb-0 wrapper d-table w-100" id="home">

    <div class="container py-4 px-0">
        <div class="p-4">
            <div class="row">
                <div class="col-lg-12 rounded" style="background-color: white;">

                    <form [formGroup]="orderDetailsForm" (ngSubmit)="onSubmit(Subscribecontent);">
                        <div class="component-wrapper rounded shadow bg-soft-blue">
                            <div class="form-group row p-4">
                                <div class="p-4 border-bottom">
                                    <h6 class="title mb-0"> <b> {{'FORMULAR.DETALIIEXP' | translate}}</b> </h6>
                                </div>
                                <div class="col-lg-4">
                                    <div class="mb-1">
                                        <label class="form-label"> {{'FORMULAR.JUDET' | translate}} <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="map-pin" class="fea icon-sm icons"></i-feather>
                                            <input name="number" type="text" class="form-control ps-5" disabled value="{{vcCounty}}">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <div class="mb-1">
                                        <label class="form-label"> {{'FORMULAR.LOCALITATE' | translate}} <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="map-pin" class="fea icon-sm icons"></i-feather>
                                            <input name="plate" type="text" class="form-control ps-5" value="{{vcLocality}}" disabled>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <div class="mb-1">
                                        <label class="form-label"> {{'FORMULAR.ADRESA' | translate}} <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="map-pin" class="fea icon-sm icons"></i-feather>
                                            <input formControlName="collecting_address" name="plate" type="text" class="form-control ps-5"
                                                placeholder="{{'FORMULAR.ADRESA' | translate}}">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row p-4">
                                <div class="col-lg-4">
                                    <div class="mb-1">
                                        <label class="form-label"> {{'FORMULAR.NUME' | translate}} <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                            <input formControlName="collecting_name" name="" type="text" class="form-control ps-5" placeholder="{{'FORMULAR.NUME' | translate}}">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <div class="mb-1">
                                        <label class="form-label"> {{'FORMULAR.TELEFON' | translate}} <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="phone" class="fea icon-sm icons"></i-feather>
                                            <input formControlName="collecting_phone" name="" type="text" class="form-control ps-5"
                                                placeholder="{{'FORMULAR.TELEFON' | translate}}">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <div class="mb-1">
                                        <label class="form-label"> Email <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                            <input formControlName="collecting_email" name="url" type="text" class="form-control ps-5" placeholder="Email">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="component-wrapper rounded shadow bg-soft-blue">
                            <div class="form-group row p-4">
                                <div class="p-4 border-bottom">
                                    <h6 class="title mb-0"><b> {{'FORMULAR.DETALIIDEST' | translate}} </b> </h6>
                                </div>
                                <div class="col-lg-4">
                                    <div class="mb-1">
                                        <label class="form-label"> {{'FORMULAR.JUDET' | translate}} <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="map-pin" class="fea icon-sm icons"></i-feather>
                                            <input name="number" type="text" class="form-control ps-5" disabled value="{{vdCounty}}">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <div class="mb-1">
                                        <label class="form-label"> {{'FORMULAR.LOCALITATE' | translate}} <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="map-pin" class="fea icon-sm icons"></i-feather>
                                            <input name="plate" type="text" class="form-control ps-5" value="{{vdLocality}}" disabled>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <div class="mb-1">
                                        <label class="form-label"> {{'FORMULAR.ADRESA' | translate}} <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="map-pin" class="fea icon-sm icons"></i-feather>
                                            <input formControlName="delivery_address" name="plate" type="text" class="form-control ps-5"
                                                placeholder="{{'FORMULAR.ADRESA' | translate}}">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row p-4">
                                <div class="col-lg-4">
                                    <div class="mb-1">
                                        <label class="form-label"> {{'FORMULAR.NUME' | translate}} <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                            <input formControlName="delivery_name" name="" type="text" class="form-control ps-5" placeholder="{{'FORMULAR.NUME' | translate}}">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <div class="mb-1">
                                        <label class="form-label"> {{'FORMULAR.TELEFON' | translate}} <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="phone" class="fea icon-sm icons"></i-feather>
                                            <input formControlName="delivery_phone" name="" type="text" class="form-control ps-5" placeholder="{{'FORMULAR.TELEFON' | translate}}">
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <br>
                        <div class="component-wrapper rounded shadow bg-soft-blue">
                            <div class="form-group row p-4">
                                <div class="p-4 border-bottom">
                                    <h6 class="title mb-0"> <b>{{'FORMULAR.DETALIICOMANDA' | translate}}</b> </h6>
                                </div>
                                <div class="col-lg-4">
                                    <div class="mb-1">
                                        <label class="form-label"> {{'FORMULAR.VALRAMB' | translate}}</label>
                                        <div class="form-icon position-relative">
                                            <input (keyup)="costCalc()" (change)="costCalc()" formControlName="ramburs_value" name="number" type="number" class="form-control"
                                                placeholder="{{'FORMULAR.VALRAMB' | translate}}:">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <div class="mb-1">
                                        <label class="form-label"> {{'FORMULAR.RAMBURSIN' | translate}}</label>
                                        <select formControlName="ramburs_type" class="form-select form-control" (keyup)="costCalc()" (change)="costCalc()" name="romania">
                                            <option value="">{{'FORMULAR.ALEGE' | translate}}</option>
                                            <option value="cash">Cash</option>
                                            <option value="cont">{{'FORMULAR.CONT' | translate}}</option>
                                            <option value="bilet">{{'FORMULAR.BILET' | translate}}</option>
                                            <option value="fila">{{'FORMULAR.FILACEC' | translate}}</option>
                                            <option value="factura">{{'FORMULAR.FACTURA' | translate}}</option>
                                            <option value="altedoc">{{'FORMULAR.ALTEDOC' | translate}}</option>
                                        </select>
                                    </div>
                                </div>

                            </div>
                            <div class="form-group row p-4">
                                <div class="col-lg-4">
                                    <div class="mb-1">
                                        <label class="form-label"> {{'FORMULAR.ASIGCOLET' | translate}}</label>
                                        <div class="form position-relative">
                                            <input formControlName="insurance_package" name="url" type="number" class="form-control" (keyup)="costCalc()" (change)="costCalc()"
                                                placeholder="{{'FORMULAR.ASIGCOLET' | translate}}">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <div class="d-flex justify-content-between ">
                                        <div class="form-check py-4 m-2">
                                            <input type="checkbox" class="form-check-input" [ngModelOptions]="{standalone: true}" (change)="costCalc()" [(ngModel)]="verifyPackage"
                                                value="{{verifyPackage}}" name="VerifColet">
                                            <label class="form-check-label">
                                                {{'FORMULAR.VERIFCOLET' | translate}}
                                            </label>
                                        </div>
                                    </div>
<!--                                    <div class="d-flex justify-content-between pt-2 ">-->
<!--                                        <div class="form-check">-->
<!--                                            <input type="checkbox" class="form-check-input" [(ngModel)]="saturdayDeliv" value="{{saturdayDeliv}}" [ngModelOptions]="{standalone: true}"-->
<!--                                                   (change)="costCalc()" name="SaturdayDelivery">-->
<!--                                            <label class="form-check-label"> {{'FORMULAR.LIVSAMBATA' | translate}}-->
<!--                                            </label>-->
<!--                                        </div>-->
<!--                                    </div>-->
                                </div>
                            </div>

                            <div class="form-group row p-4">
                                <div class="mb-3">
                                    <label class="form-label"> {{'FORMULAR.INFOSUP' | translate}} </label>
                                    <div class="form-icon position-relative">
                                        <i-feather name="message-circle" class="fea icon-sm icons"></i-feather>
                                        <textarea formControlName="comments" name="comments" id="comments" rows="4" class="form-control ps-5"
                                            placeholder="{{'FORMULAR.YMESSAGE' | translate}}"></textarea>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <br>
                        <div class="component-wrapper rounded shadow bg-soft-blue">
                            <div class="form-group row p-4">
                                <div class="p-4 border-bottom">
                                    <h6 class="title mb-0"> <b>{{'FORMULAR.DETALIIPLATA' | translate}}</b> </h6>
                                </div>

                            </div>
                            <div class="form-group row p-4">
                                <div class="col-lg-4">
                                    <div>
                                        <input formControlName="paymentType" type="radio" id="payment1" value="expeditor">
                                        <label for="payment1" class="form-check-label">&nbsp;{{'FORMULAR.PLATACARD' | translate}}</label> 
                                        <br>
                                        <input formControlName="paymentType" type="radio" id="payment2" value="destinatar"> 
                                        <label for="payment2" class="form-check-label">&nbsp;{{'FORMULAR.PLATADEST' | translate}}</label>
                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <h6 class="mb-0"> {{'FORMULAR.COST' | translate}} </h6>
                                    <p> {{vcost}} </p>
                                </div>
                            </div>
                        </div>

                        <div class=" col-lg-12 text-center col-md-4 mt-4 pt-2">
                            <input type="checkbox" class="form-check-input" formControlName="terms"> &nbsp;
                            <label class="form-check-label"> {{'FORMULAR.ACCEPT' | translate}}
                                <a href="/page-terms" target="_blank" class="text-primary"> {{'FORMULAR.TERMENI' | translate}}</a>
                            </label>
                        </div>

                        <div class=" col-lg-12 text-center col-md-4 mt-4 pt-2">
                            <button [disabled]="orderDetailsForm.invalid" type="submit" class="btn btn-primary"> {{'FORMULAR.PLASEAZA' | translate}} </button>
                        </div>
                    </form>
                    <ng-template #Subscribecontent let-modal>
                        <div class="modal-content rounded shadow border-0">
                            <div class="modal-body p-0 alert mb-0 component-wrapper rounded shadow" [ngClass]="{'alert-success': statusMessage, 'alert-danger': !statusMessage }">
                                <button type="button" class="btn-close float-end m-1" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
                                <h4 *ngIf="statusMessage" class="alert-heading text-center"> {{'FORMULAR.SUCCES' | translate}} </h4>
                                <h4 *ngIf="!statusMessage" class="alert-heading text-center"> {{'FORMULAR.!SUCCES' | translate}} </h4>
                            </div>
                        </div>
                    </ng-template>

                </div>
            </div>
        </div>
    </div>
</section>