import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StorageService } from '../../services/storage.service';
import { LoginService } from '../../services/site/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(5)]]
  });
  isLoginFailed = false;

  constructor(private fb: FormBuilder, private storageService: StorageService,
              private readonly api: LoginService, private router: Router) { }

  ngOnInit(): void {
    if (this.storageService.isLoggedIn()) {
      this.router.navigate(['/profile']);
    }
  }

  onSubmit(): void {
    const {email, password} = this.form.controls;

    this.api.login(email.value, password.value).subscribe({
      next: data => {
        this.storageService.saveUser(data);
        this.router.navigate(['/profile']);
      },
      error: err => {
        this.isLoginFailed = true;
      }
    });
    // this.form.reset();
    // this.router.navigate([`${apiName}`], {state: {
    //   username: this.form.controls.username.value
    //   }});
  }

}
