import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../services/site/login.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  form: FormGroup = new FormGroup({});
  isMessage: boolean;
  errorMessage: boolean;
  constructor(public translate: TranslateService, private api: LoginService, private fb: FormBuilder) {
    this.form = fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {
  }

  onSubmit(): void{
    const {email} = this.form.controls;

    this.api.forgotPassword(email.value, 'http://localhost:4200/resetPassword').subscribe( {

      next: data => {
        this.isMessage = true;
      },
      error: err => {
        this.errorMessage = true;
      }
    });
    this.form.reset();
  }

}
