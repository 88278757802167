import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "../../services/admin/user.service";
import { OrderService } from "../../services/site/order.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-order-formular",
  templateUrl: "./order-formular.component.html",
  styleUrls: ["./order-formular.component.css"],
})
export class OrderFormularComponent implements OnInit {
  constructor(
    private readonly orderService: OrderService,
    private readonly userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal
  ) {}

  res: string;
  statusMessage: boolean;
  vcost: any;
  vcCounty: any;
  vcLocality: any;
  vdCounty: any;
  vdLocality: any;
  details: any;
  verifyPackage: boolean;
  saturdayDeliv: boolean;
  orderDetailsForm = new FormGroup({
    collecting_address: new FormControl("", Validators.required),
    collecting_name: new FormControl("", Validators.required),
    collecting_phone: new FormControl("", Validators.required),
    collecting_email: new FormControl("", Validators.compose([Validators.required, Validators.email])),
    delivery_address: new FormControl("", Validators.required),
    delivery_name: new FormControl("", Validators.required),
    delivery_phone: new FormControl("", Validators.required),
    ramburs_value: new FormControl(""),
    insurance_package: new FormControl(""),
    ramburs_type: new FormControl(""),
    comments: new FormControl(""),
    paymentType: new FormControl(""),
    terms: new FormControl("", Validators.requiredTrue),
  });

  ngOnInit(): void {
    this.route.data.subscribe((v) => console.log(v));
    const { cost, cCounty, cLocality, dCounty, dLocality, detailsPack } = window.history.state;
    this.vcost = cost;
    this.vcCounty = cCounty;
    this.vcLocality = cLocality;
    this.vdCounty = dCounty;
    this.vdLocality = dLocality;
    this.details = JSON.parse(detailsPack);
  }

  onSubmit(content: any): void {
    this.orderService.addOrderDetails( 'RO', this.vcCounty,
        this.vcLocality, this.orderDetailsForm.controls.collecting_address.value,
        this.orderDetailsForm.controls.collecting_name.value,  this.orderDetailsForm.controls.collecting_name.value,
        this.orderDetailsForm.controls.collecting_phone.value, this.orderDetailsForm.controls.collecting_email.value,
        'RO', this.vdCounty, this.vdLocality,
        this.orderDetailsForm.controls.delivery_address.value, this.orderDetailsForm.controls.delivery_name.value,
        this.orderDetailsForm.controls.delivery_name.value, this.orderDetailsForm.controls.delivery_phone.value,
        '', this.orderDetailsForm.controls.ramburs_value.value, this.orderDetailsForm.controls.ramburs_type.value,
        'cash',
        10, this.verifyPackage,
        this.saturdayDeliv, this.orderDetailsForm.controls.paymentType.value, undefined,
        this.orderDetailsForm.controls.comments.value, this.details
    ).subscribe( {
      next: data => {
        this.statusMessage = true;
        this.modalService.open(content, { size: 'lg', windowClass: 'modal-holder', centered: true });
        setTimeout(() => {
            this.router.navigate(["/"]);
            this.modalService.dismissAll();
          }, 3000);
        },
        error: (err) => {
          this.statusMessage = false;
        this.modalService.open(content, { size: 'lg', windowClass: 'modal-holder', centered: true });
        setTimeout(() => {
            this.router.navigate(["/"]);
            this.modalService.dismissAll();
          }, 3000);
        },
      });
  }

  costCalc(): void {
    const cost = {
      collectingCounty: this.vcCounty,
      collectingLocality: this.vcLocality,
      deliveryCounty: this.vdCounty,
      deliveryLocality: this.vdLocality,
      insurancePackage: this.orderDetailsForm.controls.insurance_package.value,
      withCurrency: true,
      saturdayDelivery: this.saturdayDeliv,
      verifyPackage: this.verifyPackage,
      repaymentType: this.orderDetailsForm.controls.ramburs_type.value,
      packages: this.details,
    };

    this.orderService.postCost(cost).subscribe((res) => {
      this.vcost = res.COST === undefined ? "Indisponibil" : res.COST;
    });
  }
}
