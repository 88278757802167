import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import enLang from '../assets/i18n/en.json';
import roLang from '../assets/i18n/ro.json';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'CourierExpress';
  readonly ROOT_URL = 'https://jsonplaceholder.typicode.com';
  constructor(private router: Router, public translate: TranslateService, private http: HttpClient) {
    translate.addLangs(['ro', 'en']);
    translate.setTranslation('ro', roLang);
    translate.setTranslation('en', enLang);
    translate.setDefaultLang('ro');

    if (localStorage.getItem('lang') && localStorage.getItem('lang').length >= 1) {
      translate.use(localStorage.getItem('lang'));
    } else {
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/ro|en/) ? browserLang : 'ro');
    }

    /**
     * Unicons icon refreshed on route change.
     */
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        window['Unicons']['refresh']();
      }

      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
