import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';
import { LogoutComponent } from '../../auth/logout/logout.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-header-profile',
  templateUrl: './header-profile.component.html',
  styleUrls: ['./header-profile.component.css']
})
export class HeaderProfileComponent implements OnInit {
  currentRoute: string;
  constructor(private modalService: NgbModal, private router: Router, public translate: TranslateService) {
    this.currentRoute = '';
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Hide progress spinner or progress bar
        this.currentRoute = event.url;
      }
    });
  }

  ngOnInit(): void {
  }

  useLanguage(language: string): void {
    this.translate.use(language);
    localStorage.setItem('lang', language);
  }

  logoutConfirmation(): void {
    const modalRef = this.modalService.open(LogoutComponent);
    modalRef.componentInstance.name = '';
  }

}
