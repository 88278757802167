<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> {{'TERMS.TITLE' | translate}} </h4>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Terms & Conditions -->
<section id="content">

  <div class="content-wrap pb-0">

    <div class="container clearfix">

      <div class="row justify-content-center mb-5">
        <div class="col-lg-12 justify">
          <div class="heading-block">
            <p [innerHTML]="'TERMS.P1' | translate "></p>
            <h4 class="nott mb-3 t600 ls0"> {{'TERMS.DEFINITII' | translate}}</h4>
            <p class="mb-2" [innerHTML]="'TERMS.P2' | translate"></p>
            <p class="mb-2"> {{'TERMS.P3' | translate}} </p>
            <p class="mb-2">{{'TERMS.P4' | translate}}</p>
            <p class="mb-2"> {{'TERMS.P5' | translate}} </p>
            <p class="mb-2"> {{'TERMS.P6' | translate}} </p>

            <h4 class="nott mb-3 t600 ls0"> {{'TERMS.TITLE2' | translate}} </h4>
            <p class="mb-2" [innerHTML]="'TERMS.P7' | translate"> </p>
            <h4 class="nott mb-3 t600 ls0">{{'TERMS.TITLE3' | translate}} </h4>
            <p class="mb-2" [innerHTML]="'TERMS.P9' | translate">  </p>
            <h4 class="nott mb-3 t600 ls0">{{'TERMS.TITLE4' | translate}}  </h4>
            <p class="mb-2">  {{'TERMS.P10' | translate}} </p>
            <p class="mb-2"> {{'TERMS.P11' | translate}} </p>
            <h4 class="nott mb-3 t600 ls0"> {{'TERMS.TITLE5' | translate}} </h4>
            <p class="mb-2">{{'TERMS.P12' | translate}} </p>
            <p class="mb-2"> {{'TERMS.P13' | translate}} </p>
            <p class="mb-2" [innerHTML]="'TERMS.P14' | translate">  </p>
            <p class="mb-2"> {{'TERMS.P15' | translate}} </p>
            <p class="mb-2" [innerHTML]="'TERMS.P16' | translate">  </p>
            <p class="mb-2" [innerHTML]="'TERMS.P17' | translate">  </p>
            <p class="mb-2">  {{'TERMS.P18' | translate}}</p>
            <p class="mb-2"> {{'TERMS.P19' | translate}} </p>

            <h4 class="nott mb-3 t600 ls0"> {{'TERMS.TITLE6' | translate}} </h4>
            <p class="mb-2"> {{'TERMS.P20' | translate}} </p>
            <p class="mb-2" [innerHTML]="'TERMS.P21' | translate">  </p>
            <p class="mb-2"> {{'TERMS.P22' | translate}} </p>
            <p class="mb-2"> {{'TERMS.P23' | translate}} </p>
            <p class="mb-2" [innerHTML]="'TERMS.P24' | translate">  </p>

            <h4 class="nott mb-3 t600 ls0"> {{'TERMS.TITLE7' | translate}} </h4>
            <p class="mb-2" [innerHTML]="'TERMS.P25' | translate">  </p>

            <h4 class="nott mb-3 t600 ls0"> {{'TERMS.TITLE8' | translate}} </h4>
            <p class="mb-2" [innerHTML]="'TERMS.P26' | translate"> </p>

            <h4 class="nott mb-3 t600 ls0"> {{'TERMS.TITLE9' | translate}} </h4>
            <p class="mb-2" [innerHTML]="'TERMS.P27' | translate">  </p>
            <p class="mb-2" [innerHTML]="'TERMS.P28' | translate">  </p>
            <p class="mb-2" [innerHTML]="'TERMS.P29' | translate">  </p>
            <p class="mb-2" [innerHTML]="'TERMS.P30' | translate">   </p>
            <h4 class="nott mb-3 t600 ls0"> {{'TERMS.TITLE10' | translate}} </h4>
            <p class="mb-2" [innerHTML]="'TERMS.P31' | translate">  </p>
            <p class="mb-2" [innerHTML]="'TERMS.P32' | translate">  </p>

            <h4 class="nott mb-3 t600 ls0"> {{'TERMS.TITLE11' | translate}} </h4>
            <p class="mb-2" [innerHTML]="'TERMS.P33' | translate">  </p>
            <strong> {{'TERMS.P34' | translate}} </strong>
            <p class="mb-2" [innerHTML]="'TERMS.P35' | translate">   </p>
            <p class="mb-2" [innerHTML]="'TERMS.P36' | translate">  </p>
            <p class="mb-2" [innerHTML]="'TERMS.P37' | translate">  </p>
            <p class="mb-2" [innerHTML]="'TERMS.P38' | translate">  </p>

            <h4 class="nott mb-3 t600 ls0"> {{'TERMS.TITLE12' | translate}} </h4>

            <p class="mb-2" [innerHTML]="'TERMS.P39' | translate">  </p>

            <h4 class="nott mb-3 t600 ls0"> {{'TERMS.TITLE13' | translate}} </h4>
            <p class="mb-2" [innerHTML]="'TERMS.PLIMITELERASP' | translate">  </p>

            <h4 class="nott mb-3 t600 ls0">  {{'TERMS.TITLE14' | translate}} </h4>
            <p class="mb-2" [innerHTML]="'TERMS.PEXCEPTIIDERASP' | translate">  </p>


            <h4 class="nott mb-3 t600 ls0"> {{'TERMS.TITLE15' | translate}} </h4>

            <p class="mb-2" [innerHTML]="'TERMS.PSERVICII' | translate">  </p>

            <h4 class="nott mb-3 t600 ls0"> {{'TERMS.TITLE16' | translate}} </h4>
            <p class="mb-2" [innerHTML]="'TERMS.PBUNURI' | translate"> </p>

            <h4 class="nott mb-3 t600 ls0">  {{'TERMS.TITLE17' | translate}} </h4>
            <p class="mb-2" [innerHTML]="'TERMS.PRASPUNDERE' | translate"> </p>

            <h4 class="nott mb-3 t600 ls0"> {{'TERMS.TITLE18' | translate}} </h4>
            <p class="mb-2" [innerHTML]="'TERMS.PPLANGERI' | translate">  </p>

            <h4 class="nott mb-3 t600 ls0"> {{'TERMS.TITLE19' | translate}} </h4>
            <p class="mb-2" [innerHTML]="'TERMS.PPROCEDURI' | translate">  </p>

            <h4 class="nott mb-3 t600 ls0"> {{'TERMS.TITLE20' | translate}} </h4>
            <p class="mb-2" [innerHTML]="'TERMS.P200' | translate ">  </p>
            <p class="mb-2" [innerHTML]="'TERMS.P201' | translate ">  </p>
            <p class="mb-2" [innerHTML]="'TERMS.P202' | translate ">  </p>
            <p class="mb-2" [innerHTML]="'TERMS.P203' | translate ">  </p>
            <p class="mb-2" [innerHTML]="'TERMS.P204' | translate ">  </p>

            <h4 class="nott mb-3 t600 ls0"> {{'TERMS.TITLE21' | translate}} </h4>
            <p class="mb-2" [innerHTML]="'TERMS.PDESPAGUBIRE' | translate">  </p>

            <h4 class="nott mb-3 t600 ls0"> {{'TERMS.TITLE22' | translate}} </h4>
            <p class="mb-2" [innerHTML]="'TERMS.PJURIS' | translate">  </p>
          </div>
        </div>

      </div>

    </div>

    <div class="clear"></div>

  </div>

</section>
